<template>
	<div class="carousel_card">
		<el-carousel :interval="3000" type="card" height="490px">
			<!-- :type="cardData.length > 3 ? 'card' : ''" -->
			<el-carousel-item v-for="(item, index) in cardData" :key="index">
				<div
					class="item"
					:style="{ backgroundImage: 'url(' + item.img + ')' }"
				>
					<div class="title">{{ item.text }}</div>
					<div class="desc">{{ item.desc }}</div>
					<router-link :to="item.url" class="icon_box"
						><div class="icon">
							<img
								src="../assets/btn.png"
								alt=""
								title="详情"
							/></div
					></router-link>
				</div>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
export default {
	props: {
		cardData: {
			type: Array,
			default: () => []
		}
	}
}
</script>

<style scoped lang="scss">
.carousel_card {
	// padding: 0 100px;
	padding: 0 10%;
	background-color: #fff;
	&[dark] {
		background-color: #1d1f1d;
	}
	&[noPadding] {
		padding: 0;
	}
	.item {
		color: #fff;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-sizing: border-box;
		padding: 0 50px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		.title {
			// font-size: 22px;
			font-size: 26px;
		}
		.desc {
			// font-size: 14px;
			font-size: 18px;
		}
		.icon_box{
			display: inline-block;
			width: 46px;
		}
		.icon {
			height: 46px;
			width: 46px;
			display: inline-block;
			img {
				width: 100%;
			}
			// border: 2px solid #fff;
			margin-top: 30px;
		}
		div + div {
			margin-top: 30px;
		}
	}
}
</style>
<style scoped lang="scss">
.carousel_card {
	.el-carousel__item:nth-child(2n) {
		background-color: blue;
	}

	.el-carousel__item:nth-child(2n + 1) {
		background-color: red;
	}
}
</style>
