<template>
	<div>
		<div class="banner">
			<div class="title">IOT物联网平台</div>
			<div class="title_sec">可扩展、可伸缩、高性能、通用性</div>
			<div class="desc">
				<ul>
					<li>
						工业级物联网数据集成与数据资源管理系统，支持全行业设备联网和低代码监控软件搭建。
					</li>
					<li>
						提供从协议管理、网关管理、数据接入、消息服务、策略引擎、故障诊断、<br/>可视化监控等一站式物联网产品功能与服务。
					</li>
				</ul>
			</div>
			<!-- <div class="btn">
				<div class="banner_btn">立即试用</div>
			</div> -->
		</div>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">技术框架</div>
				<div class="en">Technical Framework</div>
			</div>
			<div class="titles">
				<div class="box">
					<div>全面感知</div>
					<div>实时分析</div>
					<div>科学决策</div>
					<div>精准实施</div>
				</div>
			</div>
			<div class="center_img">
				<img src="../../assets/product/iot6.png" alt=""  style="width:940px;"/>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">核心能力</div>
				<div class="en">Core Competences</div>
			</div>
		</div>
		<LeftRightTree :treeData="tree"></LeftRightTree>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">应用场景</div>
				<div class="en">Application Scenarios</div>
			</div>
		</div>
		<Carousel :cardData="cards"></Carousel>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Carousel from '../../components/Carousel.vue'
import LeftRightTree from '../../components/LeftRightTree.vue'
export default {
	components: {
		Carousel,
		LeftRightTree
	},
	setup() {
		const state = reactive({
			cards: [
				{
					text: '物联网基础数据服务中台',
					desc: '提供基础数据标准API，与业务系统完全解耦',
					url: '/solution/iot',
					img: require('../../assets/bc/3.png')
				},
				{
					text: '零碳工厂与零碳园区',
					desc: '以节能服务、碳资产管理赋能零碳工厂与零碳园区建设',
					url: '/solution/zerocarbon',
					img: require('../../assets/bc/11.png')
				},
				{
					text: '钢铁业',
					desc: '助力钢铁企业“百万设备上网”',
					url: '/industry/iron',
					img: require('../../assets/bc/13.png')
				},
				{
					text: '纺织业',
					desc: '纺织设备数据联网与节能控制优化',
					url: '/industry/spin',
					img: require('../../assets/bc/14.png')
				},
				{
					text: '智慧园区',
					desc: '园区设备在线监控与智能运维',
					url: '/industry/park',
					img: require('../../assets/bc/15.png')
				}
			],
			tree: [
				{
					title: '简单、灵活的设备接入管理',
					list: [
						'支持7大类南向通讯协议，3大类北向传输协议',
						'支持私有协议定制开发',
						'云边一体化设备调试，提供边缘协议驱动开源代码',
						'遵循国家标准编码规则',
						'从标准属性、标准类别到标准模型的完整数字建模框架'
					],
					img: require('../../assets/product/iot1.png')
				},
				{
					title: '夯实的工业物联网数字服务基座',
					list: [
						'采用时序数据库，毫秒级摄取海量IOT数据',
						'基于滑动时间窗口的聚集计算',
						'动态扩容、负载均衡和断点续传',
						'提供Restful规范的数据访问接口',
						'提供MQTT数据订阅与发布服务',
						'基于OpenID的身份认证系统，支持单点登录'
					],
					img: require('../../assets/product/iot2.png')
				},
				{
					title: '零代码引擎，协助用户高效管理物联网设备',
					list: [
						'策略引擎：告警策略、设备控制策略的拖拽式设计',
						'BI可视化：BI报表、大屏仪表板、IOT数据看板的零代码编辑',
						'组态引擎：系统工艺流程组态图形的零代码设计',
						'指标设计：丰富的指标设计与函数模板，为工艺及节能优化量身打造'
					],
					img: require('../../assets/product/iot3.png')
				},
				{
					title: '能源管理与分项计量，为实现碳中和提供优化建议',
					list: [
						'依据行业标准，对项目内的水、电、燃、热力系统进行分类、分项监测',
						'专家策略引擎为暖通及热力系统提供节能控制策略',
						'为高能耗用户提供AI深度节能优化服务',
						'为行业用户提供合同能源管理服务'
					],
					img: require('../../assets/product/iot4.png')
				},
				{
					title: '多租户架构，满足信创标准',
					list: [
						'分布式数据存储，优化的混合组网方案',
						'支持多租户管理与多级权限分配',
						'提供云-雾-边多级私有化平台搭建',
						'为连锁商业提供SaaS+IOT的轻量级服务',
						'满足数据安全与隔离，支持政府数据等保与国密标准',
						'支持鸿蒙OS、信创网关及操作系统',
						'具有自主知识产权的纯国产应用'
					],
					img: require('../../assets/product/iot5.png')
				}
			]
		})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style scoped lang="scss">
.banner {
	background-image: url(../../assets/bc/18.png);
}
li {
	list-style: disc;
}
</style>
