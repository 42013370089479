<template>
	<div>
		<div class="banner">
			<div class="title">IOT物联网平台</div>
			<div class="title_sec">可扩展、可伸缩、高性能、通用性</div>
			<div class="desc">
				<ul>
					<li>
						工业级物联网数据集成与数据资源管理系统，支持全行业设备联网和低代码监控软件搭建。
					</li>
					<li>
						提供从协议管理、网关管理、数据接入、消息服务、策略引擎、故障诊断、<br />可视化监控等一站式物联网产品功能与服务。
					</li>
				</ul>
			</div>
		</div>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">IOT物联网平台 产品报价</div>
				<div class="en">Product quotation</div>
			</div>
		</div>
		<div class="iot-price">
			<div class="whole-table">
				<div class="table-row flex">
					<div class="row-one ft16 bold mr3">
						<span class="circular"></span>
						类别
						<el-icon
							class="drrowRight"
							:class="[allOpen ? 'upIcon' : '']"
							@click="allCheck"
							><DArrowRight
						/></el-icon>
					</div>
					<div class="row-one ft16 bold mr3 w160">
						<span class="circular"></span>
						功能
					</div>
					<div class="row-three ft16 bold mr3 w160">
						<span class="circular"></span>
						私有部署版
					</div>
					<div class="row-three ft16 bold mr3 w160"></div>
					<div class="row-three ft16 bold mr3 w160"></div>
					<div class="row-three ft16 bold mr3 w160"></div>
					<div class="row-four ft16 bold mr3">
						<span class="circular"></span>
						云服务版
					</div>
					<div class="row-four ft16 bold"></div>
				</div>
				<div class="table-row flex mt10 mb10">
					<div class="row-one ft16 bold mr3"></div>
					<div class="row-one ft16 bold mr3 w160"></div>
					<div class="row-three ft16 bold mr3 w160">
						<span class="circular opac"></span>
						免费版
					</div>
					<div class="row-three ft16 bold mr3 w160">专业版</div>
					<div class="row-three ft16 bold mr3 w160">企业版</div>
					<div class="row-three ft16 bold mr3 w160">定制版</div>
					<div class="row-four ft16 bold mr3">
						<span class="circular opac"></span>
						标准版
					</div>
					<div class="row-four ft16 bold">旗舰版</div>
				</div>
				<div
					class="table-row flex mb3 flexw mb3"
					v-for="(item, i) in tableData"
					:key="item.title"
				>
					<div
						class="row-one ft16 bgcf bold mr3 leftTitle"
						v-if="item.isOpen"
					>
						<p
							class="title-text"
							:style="{ 'border-left-color': item.borCol }"
							@click="toggleHide(item)"
						>
							{{ item.title }}
							<el-icon v-if="i > 2" class="drrowRight upIcon"
								><DArrowRight
							/></el-icon>
							<span
								style="color: #0388fc; margin-left: 3px"
								v-if="item.titleIcon"
							>
								{{ item.titleIcon }}</span
							>
						</p>
					</div>
					<div class="wAll" v-if="item.isOpen">
						<div
							class="row-two ft14 flex"
							v-for="item1 in item.children"
							:key="item1.one"
						>
							<div class="row-one bgcf mr3 w160">
								<p>
									{{ item1.one }}
									<span
										style="color: #0388fc"
										v-if="item1.icon"
										>{{ item1.icon }}</span
									>
								</p>
								<p v-if="item1.oneTitle">
									{{ item1.oneTitle }}
								</p>
							</div>
							<div class="row-one bgcf mr3 w160">
								<p>{{ item1.two }}</p>
								<p v-if="item1.twoText">{{ item1.twoText }}</p>
								<el-icon v-if="item1.twoIcon === 'd'"
									><Check
								/></el-icon>
								<el-icon v-if="item1.twoIcon === 'c'"
									><Close
								/></el-icon>
							</div>
							<div
								class="row-one bgcf mr3 w160"
								v-if="!item1.custStyle"
							>
								<p>{{ item1.three }}</p>
								<p v-if="item1.threeText">
									{{ item1.threeText }}
								</p>
								<el-icon v-if="item1.threeIcon === 'd'"
									><Check
								/></el-icon>
								<el-icon v-if="item1.threeIcon === 'c'"
									><Close
								/></el-icon>
							</div>
							<div
								class="row-one bgcf mr3 w160"
								v-if="!item1.custStyle"
							>
								<p>{{ item1.four }}</p>
								<p v-if="item1.fourText">
									{{ item1.fourText }}
								</p>
								<el-icon v-if="item1.fourIcon === 'd'"
									><Check
								/></el-icon>
								<el-icon v-if="item1.fourIcon === 'c'"
									><Close
								/></el-icon>
							</div>
							<div
								class="row-one bgcf mr3 w160"
								v-if="!item1.custStyle"
							>
								<p>{{ item1.five }}</p>
								<el-icon v-if="item1.fiveIcon === 'd'"
									><Check
								/></el-icon>
								<el-icon v-if="item1.fiveIcon === 'c'"
									><Close
								/></el-icon>
							</div>
							<div
								class="row-one bgcf mr3 w200"
								v-if="!item1.custStyle"
							>
								<p>{{ item1.six }}</p>
								<p v-if="item1.sixText">{{ item1.sixText }}</p>
								<el-icon v-if="item1.sixIcon === 'd'"
									><Check
								/></el-icon>
								<el-icon v-if="item1.sixIcon === 'c'"
									><Close
								/></el-icon>
							</div>
							<div
								class="row-one bgcf w200"
								v-if="!item1.custStyle"
							>
								<p>{{ item1.seven }}</p>
								<p v-if="item1.sevenText">
									{{ item1.sevenText }}
								</p>
								<el-icon v-if="item1.sevenIcon === 'd'"
									><Check
								/></el-icon>
								<el-icon v-if="item1.sevenIcon === 'c'"
									><Close
								/></el-icon>
							</div>
							<div v-if="item1.alarmText" class="wright">
								{{ item1.alarmText }}
							</div>

							<div v-if="item1.noticeOne" class="noticeOne flex">
								<p class="mr3">{{ item1.noticeOne }}</p>
								<p>{{ item1.noticeTwo }}</p>
							</div>
						</div>
					</div>
					<div v-if="!item.isOpen" class="hideClass">
						<p @click="toggleHide(item)">
							{{ item.title }}
							<el-icon class="drrowRight"
								><DArrowRight
							/></el-icon>
						</p>
					</div>
				</div>
				<div class="explain bold" style="margin-top: 20px">说明</div>
				<p class="ft14">
					①：专业版、企业版、定制版用户可自选第三方短信服务，也可采购领环短信服务，若为后者，则按条计费，每月结算，0.05元/条；
				</p>
				<p class="ft14">
					②：通过负载均衡、分布式部署提高平台运行的可靠性；
				</p>
				<p class="ft14">
					③：包括7大南向通信协议，分别为ModBus, DL/T645, CJ/T188, OPC
					UA, KNX, BACnet/IP, CAN，和3大北向传输协议，分别为：MQTT,
					CoAP(NBIOT), HTTP；
				</p>
				<p class="ft14">
					④：销售价格已包括指定的并发访问量，如需增加并发量，需额外购买；
				</p>
				<p class="ft14">
					⑤：包括服务器容器环境部署、操作系统安装、IOT平台安装、服务器维护服务、网络配置服务；
				</p>
				<p class="ft14">
					⑥：包括用户所选硬件的协议配置或开发、数据接入、云-边数据调试、物模型录入、边缘网关软件配置与调试、账号及权限配置、空间及设备树信息配置、北向数据开放支持、系统使用及维护培训。
				</p>
				<p class="ft14" style="margin-top: 20px">
					申请“<span class="onTrial" @click="goTrial">免费试用</span
					>”，即刻获取IOT物联网平台免费版安装包。
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useRouter } from 'vue-router'
export default {
	components: {},
	setup() {
		const router = useRouter()
		const state = reactive({
			tableData: [
				{
					isOpen: true,
					title: '报价',
					borCol: '#0388FC',
					children: [
						{
							one: '初始购置价格',
							two: '免费',
							three: '24,000元',
							four: '14,4000 元',
							five: '联系销售',
							six: '27,600 元（含一型边缘网关*1+1年服务）',
							seven: '39,600 元（含二型信创网关*1+1年服务'
						},
						{
							one: '扩容升级费',
							two: '-',
							three: '24,000元/100个设备',
							four: '144,000 元/1,000个设备',
							five: '联系销售',
							six: '60元/10个设备，月',
							seven: '60元/10个设备，月'
						},
						{
							one: '订阅年费',
							two: '-',
							three: '15%（第二年起）',
							four: '15%（第二年起）',
							five: '联系销售',
							six: '1,000元/月（第二年起）',
							seven: '1,600元/月（第二年起）'
						},
						{
							one: '使用期技术支持',
							oneTitle: '（超时服务）',
							two: '开发者论坛交流',
							three: '300元/小时',
							four: '300元/小时',
							five: '联系销售',
							six: '300元/小时',
							seven: '300元/小时'
						},
						{
							one: '培训服务',
							oneTitle: '（超次服务）',
							two: '-',
							three: '2,000元/次',
							four: '2,000元/次',
							five: '联系销售',
							six: '2,000元/次',
							seven: '2,000元/次'
						},
						{
							one: '短信服务',
							icon: '①',
							oneTitle: '',
							two: '-',
							three: '0.05元/条',
							four: '0.05元/条',
							five: '0.05元/条',
							six: '赠送100条/月，超出部分0.05元/条',
							seven: '赠送300条/月，超出部分0.05元/条'
						},
						{
							one: '云服务存储增容',
							icon: '',
							oneTitle: '',
							two: '-',
							three: '-',
							four: '-',
							five: '-',
							six: '300元/100GiB/年',
							seven: '300元/100GiB/年'
						},
						{
							one: '高可用支持',
							icon: '②',
							oneTitle: '',
							two: '-',
							three: '-',
							four: '联系销售',
							five: '联系销售',
							six: '已含',
							seven: '已含'
						}
					]
				},
				{
					isOpen: true,
					title: '用户类型',
					borCol: '#886EFE',
					children: [
						{
							one: '',
							icon: '',
							oneTitle: '',
							two: '个人学习/小规模试用',
							three: '常规使用',
							four: '跨地域/多部门',
							five: '行业客户/集团用户',
							six: '小型项目',
							seven: '中大型项目'
						}
					]
				},
				{
					isOpen: true,
					title: '应用规模',
					borCol: '#0388FC',
					children: [
						{
							one: '初始数量',
							icon: '',
							oneTitle: '',
							two: '设备数量：≤20个',
							twoText: '采集点位：≤100个',
							three: '设备数量：≤100个',
							threeText: '采集点位：≤1,000个',
							four: '设备数量：≤1,000个',
							fourText: '采集点位：≤10,000个',
							five: '无限制',
							six: '设备数量：≤100个',
							sixText: '采集点位：≤1,000个',
							seven: '设备数量：≤300个',
							sevenText: '采集点位：≤3,000个'
						},
						{
							one: '扩容升级',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '按100个设备/1,000个点位递增',
							threeText: '',
							four: '按1,000个设备/ 10,000个点位递增',
							fourText: '',
							five: '最高1百万数据并发量',
							six: '按100个设备/1,000个点位递增',
							sixText: '',
							seven: '按100个设备/1,000个点位递增',
							sevenText: ''
						},
						{
							one: '数据存储',
							icon: '',
							oneTitle: '',
							two: '本地',
							twoText: '',
							three: '本地',
							threeText: '',
							four: '本地',
							fourText: '',
							five: '本地',
							six: '云端50GiB起',
							sixText: '',
							seven: '云端100GiB起',
							sevenText: ''
						}
					]
				},
				{
					isOpen: false,
					title: '平台基础功能',
					borCol: '#886EFE',
					children: [
						{
							one: '空间管理',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							seven: '',
							sevenIcon: 'd'
						},
						{
							one: '组织管理',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							seven: '',
							sevenIcon: 'd'
						},
						{
							one: '人员管理',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							seven: '',
							sevenIcon: 'd'
						},
						{
							one: '权限管理',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							seven: '',
							sevenIcon: 'd'
						},
						{
							one: '告警管理',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							seven: '',
							sevenIcon: 'd'
						},
						{
							one: '消息推送',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							seven: '',
							sevenIcon: 'd'
						}
					]
				},
				{
					isOpen: false,
					title: '物联标准功能',
					borCol: '#0388FC',
					children: [
						{
							one: '设备建模',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							seven: '',
							sevenIcon: 'd'
						},
						{
							one: '设备管理',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							seven: '',
							sevenIcon: 'd'
						},
						{
							one: '设备接入',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							seven: '',
							sevenIcon: 'd'
						},
						{
							one: '设备监控',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							seven: '',
							sevenIcon: 'd'
						},
						{
							one: '摄像头接入',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'c',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'c',
							six: '',
							seven: '',
							sevenIcon: 'd'
						},
						{
							custStyle: true,
							alarmText:
								'告警策略+设备策略+联动策略+空间策略+顺控策略',
							one: '策略引擎',
							icon: '',
							oneTitle: '',
							two: '告警策略+设备策略',
							twoIcon: '',
							twoText: '',
							three: '',
							threeIcon: '',
							fourIcon: '',
							four: '',
							fiveIcon: '',
							five: '',
							sixIcon: '',
							six: '',
							seven: '',
							sevenIcon: ''
						},
						{
							custStyle: true,
							one: '事件通知',
							icon: '',
							oneTitle: '',
							two: '邮件',
							noticeOne: '邮件+短信+微信+钉钉',
							noticeTwo: '邮件+短信+语音+微信+小程序',
							twoIcon: '',
							twoText: '',
							three: '',
							threeIcon: '',
							fourIcon: '',
							four: '',
							fiveIcon: '',
							five: '',
							sixIcon: '',
							six: '',
							seven: '',
							sevenIcon: ''
						},
						{
							one: '组态HMI-2D场景',
							icon: '',
							oneTitle: '',
							two: '3张图纸',
							twoIcon: '',
							twoText: '',
							three: '10张图纸',
							threeIcon: '',
							fourIcon: '',
							four: '50张图纸',
							fiveIcon: '',
							five: '不限制',
							sixIcon: '',
							six: '5张图纸',
							seven: '15张图纸',
							sevenIcon: ''
						},
						{
							one: '组态模板',
							icon: '',
							oneTitle: '',
							two: '演示版',
							twoIcon: '',
							twoText: '',
							three: '增值服务',
							threeIcon: '',
							fourIcon: '',
							four: '增值服务',
							fiveIcon: '',
							five: '增值服务',
							sixIcon: '',
							six: '增值服务',
							seven: '增值服务',
							sevenIcon: ''
						}
					]
				},
				{
					isOpen: false,
					title: '物联通讯功能',
					borCol: '#886EFE',
					children: [
						{
							one: '边缘网关软件',
							icon: '',
							oneTitle: '',
							two: '开源',
							twoIcon: '',
							twoText: '',
							three: '开源',
							threeIcon: '',
							fourIcon: '',
							four: '开源',
							fiveIcon: '',
							five: '开源',
							sixIcon: '',
							six: '开源',
							sevenIcon: '',
							seven: '开源'
						},
						{
							one: '设备接入与调试',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							sevenIcon: 'd',
							seven: ''
						},
						{
							one: '通讯协议',
							icon: '③',
							oneTitle: '',
							two: 'ModBus+MQTT',
							twoIcon: '',
							twoText: '',
							three: '全部',
							threeIcon: '',
							fourIcon: '',
							four: '全部',
							fiveIcon: '',
							five: '全部',
							sixIcon: '',
							six: '全部',
							sevenIcon: '',
							seven: '全部'
						},
						{
							one: '高频数据采集',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'c',
							fourIcon: 'c',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'c',
							six: '',
							sevenIcon: 'd',
							seven: ''
						},
						{
							one: '私有协议接入',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							sevenIcon: 'd',
							seven: ''
						},
						{
							one: '私有驱动开发',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							sevenIcon: 'd',
							seven: ''
						},
						{
							one: '委托驱动开发',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '联系销售',
							threeIcon: '',
							fourIcon: '',
							four: '联系销售',
							fiveIcon: '',
							five: '联系销售',
							sixIcon: '',
							six: '联系销售',
							sevenIcon: '',
							seven: '联系销售'
						}
					]
				},
				{
					isOpen: false,
					title: '数据分析功能',
					borCol: '#0388FC',
					children: [
						{
							one: '数据大屏',
							icon: '',
							oneTitle: '',
							two: '标准',
							twoIcon: '',
							twoText: '',
							three: '标准',
							threeIcon: '',
							fourIcon: '',
							four: '增强',
							fiveIcon: '',
							five: '增强',
							sixIcon: '',
							six: '增强',
							sevenIcon: '',
							seven: '增强'
						},
						{
							one: '数据报表',
							icon: '',
							oneTitle: '',
							two: '标准',
							twoIcon: '',
							twoText: '',
							three: '标准',
							threeIcon: '',
							fourIcon: '',
							four: '增强',
							fiveIcon: '',
							five: '增强',
							sixIcon: '',
							six: '增强',
							sevenIcon: '',
							seven: '增强'
						},
						{
							one: 'BI设计工具',
							icon: '④',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'c',
							fourIcon: '',
							four: '两用户并发起',
							fiveIcon: '',
							five: '两用户并发起',
							sixIcon: '',
							six: '联系销售',
							sevenIcon: '',
							seven: '联系销售'
						}
					]
				},
				{
					isOpen: false,
					title: '扩展模块',
					borCol: '#886EFE',
					children: [
						{
							one: '组态HMI-3D场景',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'c',
							fourIcon: '',
							four: '联系销售',
							fiveIcon: '',
							five: '联系销售',
							sixIcon: '',
							six: '联系销售',
							sevenIcon: '',
							seven: '联系销售'
						},
						{
							one: '作业管理',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							sevenIcon: 'd',
							seven: ''
						},
						{
							one: '设备运维',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'c',
							fourIcon: 'c',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'c',
							six: '',
							sevenIcon: 'c',
							seven: ''
						},
						{
							one: '工单管理',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'c',
							fourIcon: 'c',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'c',
							six: '',
							sevenIcon: 'c',
							seven: ''
						},
						{
							one: '工作流',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'c',
							fourIcon: 'c',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'c',
							six: '',
							sevenIcon: 'c',
							seven: ''
						},
						{
							one: 'AI诊断',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'c',
							fourIcon: '',
							four: '联系销售',
							fiveIcon: '',
							five: '联系销售',
							sixIcon: 'c',
							six: '',
							sevenIcon: '',
							seven: '联系销售'
						},
						{
							one: 'MES集成',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'c',
							fourIcon: 'c',
							four: '',
							fiveIcon: '',
							five: '联系销售',
							sixIcon: '',
							six: '联系销售',
							sevenIcon: '',
							seven: '联系销售'
						},
						{
							one: '单点登录集成',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '联系销售',
							threeIcon: '',
							fourIcon: '',
							four: '联系销售',
							fiveIcon: '',
							five: '联系销售',
							sixIcon: 'c',
							six: '',
							sevenIcon: '',
							seven: '联系销售'
						}
					]
				},
				{
					isOpen: false,
					title: '软件部署',
					borCol: '#0388FC',
					children: [
						{
							one: '部署模式',
							icon: '',
							oneTitle: '',
							two: '单机',
							twoIcon: '',
							twoText: '',
							three: '单机',
							threeIcon: '',
							fourIcon: '',
							four: '单机/集群',
							fiveIcon: '',
							five: '单机/双机/集群',
							sixIcon: '',
							six: '多租户集群+数据库隔离',
							sevenIcon: '',
							seven: '多租户集群+数据库隔离'
						},
						{
							one: '部署方案',
							icon: '',
							oneTitle: '',
							two: '容器化',
							twoIcon: '',
							twoText: '',
							three: '容器化',
							threeIcon: '',
							fourIcon: '',
							four: '容器化+K8S+操作系统',
							fiveIcon: '',
							five: '容器化+K8S+操作系统',
							sixIcon: '',
							six: 'K8S',
							sevenIcon: '',
							seven: 'K8S'
						},
						{
							one: 'CPU支持',
							icon: '',
							oneTitle: '',
							two: 'X86',
							twoIcon: '',
							twoText: '',
							three: 'X86',
							threeIcon: '',
							fourIcon: '',
							four: 'X86/ARM64',
							fiveIcon: '',
							five: 'X86/ARM64',
							sixIcon: '',
							six: 'X86/ARM64',
							sevenIcon: '',
							seven: 'X86/ARM64'
						},
						{
							one: '操作系统',
							icon: '',
							oneTitle: '',
							two: 'Linux',
							twoIcon: '',
							twoText: '',
							three: 'Linux',
							threeIcon: '',
							fourIcon: '',
							four: 'Linux',
							fiveIcon: '',
							five: 'Linux',
							sixIcon: '',
							six: 'Linux',
							sevenIcon: '',
							seven: 'Linux'
						},
						{
							one: '软件装机及服务器维护',
							icon: '⑤',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '联系销售',
							threeIcon: '',
							fourIcon: '',
							four: '联系销售',
							fiveIcon: '',
							five: '联系销售',
							sixIcon: '',
							six: '-',
							sevenIcon: '',
							seven: '-'
						},
						{
							one: '数据接入与交钥匙服务',
							icon: '⑥',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '联系销售',
							threeIcon: '',
							fourIcon: '',
							four: '联系销售',
							fiveIcon: '',
							five: '联系销售',
							sixIcon: '',
							six: '联系销售',
							sevenIcon: '',
							seven: '联系销售'
						}
					]
				},
				{
					isOpen: false,
					title: '售后支持',
					borCol: '#886EFE',
					children: [
						{
							one: '呼叫响应',
							icon: '',
							oneTitle: '',
							two: '5*8小时',
							twoIcon: '',
							twoText: '',
							three: '5*8小时',
							threeIcon: '',
							fourIcon: '',
							four: '5*8小时',
							fiveIcon: '',
							five: '7*24小时',
							sixIcon: '',
							six: '5*8小时',
							sevenIcon: '',
							seven: '5*8小时'
						},
						{
							one: '技术支持',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '8小时',
							threeIcon: '',
							fourIcon: '',
							four: '48小时',
							fiveIcon: '',
							five: '联系销售',
							sixIcon: '',
							six: '8小时/年',
							sevenIcon: '',
							seven: '8小时/年'
						},
						{
							one: '培训支持',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '1次免费在线培训',
							threeIcon: '',
							fourIcon: '',
							four: '1次免费在线培训',
							fiveIcon: '',
							five: '联系销售',
							sixIcon: '',
							six: '每年1次免费在线培训',
							sevenIcon: '',
							seven: '每年1次免费在线培训'
						},
						{
							one: '产品升级',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '订阅期内免费升级',
							threeIcon: '',
							fourIcon: '',
							four: '订阅期内免费升级',
							fiveIcon: '',
							five: '联系销售',
							sixIcon: '',
							six: '订阅期内免费升级',
							sevenIcon: '',
							seven: '订阅期内免费升级'
						},
						{
							one: '数据备份',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'c',
							fourIcon: 'c',
							four: '',
							fiveIcon: '',
							five: '联系销售',
							sixIcon: '',
							six: '数据备份、导出，无供应商锁定',
							sevenIcon: '',
							seven: '数据备份、导出，无供应商锁定'
						}
					]
				},
				{
					isOpen: false,
					title: '开发支持',
					borCol: '#0388FC',
					children: [
						{
							one: '论坛支持',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							sevenIcon: 'd',
							seven: ''
						},
						{
							one: '邮件支持',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							sevenIcon: 'd',
							seven: ''
						},
						{
							one: '电话支持',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '5*8小时',
							threeIcon: '',
							fourIcon: '',
							four: '5*8小时',
							fiveIcon: '',
							five: '7*24小时',
							sixIcon: '',
							six: '8小时',
							sevenIcon: '',
							seven: '8小时'
						},
						{
							one: '企业微信',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'd',
							six: '',
							sevenIcon: 'd',
							seven: ''
						},
						{
							one: '二次开发',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							twoText: '',
							three: '',
							threeIcon: 'c',
							fourIcon: 'c',
							four: '',
							fiveIcon: 'd',
							five: '',
							sixIcon: 'c',
							six: '',
							sevenIcon: 'c',
							seven: ''
						}
					]
				}
			],
			allOpen: computed(() =>
				state.tableData.every((item) => item.isOpen)
			)
		})

		const goTrial = () => {
			router.push('/freetrial')
		}
		const allCheck = () => {
			let bool = !state.allOpen
			state.tableData.forEach((item, i) => {
				if (i > 2) {
					item.isOpen = bool
				}
			})
		}
		// 切换显示隐藏
		const toggleHide = (row) => {
			row.isOpen = !row.isOpen
		}
		return {
			...toRefs(state),
			goTrial,
			toggleHide,
			allCheck
		}
	}
}
</script>

<style scoped lang="scss">
.drrowRight {
	transform: rotate(90deg);
	position: relative;
	top: 2px;
	font-size: 16px;
	margin-left: 3px;
	cursor: pointer;
}
.upIcon {
	transform: rotate(-90deg);
}
.banner {
	background-image: url(../../assets/bc/18.png);
}
li {
	list-style: disc;
}
.ft16 {
	font-size: 16px;
}
.ft14 {
	font-size: 14px;
}
.bold {
	font-weight: 700;
}
.flex {
	display: flex;
}
.flexw {
	flex-wrap: wrap;
}
.mb10 {
	margin-bottom: 10px;
}
.mt10 {
	margin-top: 10px;
}
.mr3 {
	margin-right: 3px;
}
.mb3 {
	margin-bottom: 3px;
}
.w160 {
	// width: 200px !important;
	width: calc((100% - 18px) / 7) !important;
}
.wright {
	// width: calc(100% - 406px);
	width: calc(100% - ((100% - 18px) / 7) * 2);
	padding: 20px;
	box-sizing: border-box;
	background-color: #fff;
	text-align: center;
}
.noticeOne {
	// width: calc(100% - 406px);
	width: calc(100% - ((100% - 18px) / 7) * 2);
	p {
		width: calc((100% - 3px) / 2);
		padding: 20px;
		box-sizing: border-box;
		background-color: #fff;
		text-align: center;
	}
}
.iot-price {
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
	.whole-table {
		width: 100%;
		padding: 0 50px;
	}
	.row-one {
		padding-left: 15px;
		box-sizing: border-box;
		// width: 150px;
		width: 10%;
	}
	.row-three {
		padding-left: 15px;
		box-sizing: border-box;
		// width: 150px;
	}
	.row-four {
		padding-left: 15px;
		box-sizing: border-box;
		// width: 200px;
		width: calc((100% - 18px) / 7);
	}
	.w200 {
		// width: 200px;
		width: calc((100% - 18px) / 7);
	}
	.wAll {
		width: calc(100% - 10% - 3px);
		.w160 {
			width: calc((100% - 18px) / 7) !important;
		}
		.row-two {
			margin-bottom: 3px;
			&:nth-last-child(1) {
				margin-bottom: 0;
			}
		}
	}
	.row-two {
		.row-one {
			padding: 20px;
			box-sizing: border-box;
		}
	}
	.bgcf {
		background-color: #fff;
	}
	.circular {
		display: inline-block;
		width: 7px;
		height: 7px;
		border-radius: 50%;
		background-color: #0388fc;
		position: relative;
		top: -1px;
	}
	.opac {
		opacity: 0;
	}
	.leftTitle {
		padding: 6px;
		box-sizing: border-box;
		.title-text {
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: 2px solid #0388fc;
			height: 100%;
		}
	}
	.borLeft {
		border-left-color: #886efe !important;
	}
	.onTrial {
		cursor: pointer;
		color: #0388fc;
	}
	.hideClass {
		width: 100%;
		background-color: #fff;
		padding: 20px;
		text-align: center;
	}
}
</style>
