import { createRouter, createWebHashHistory } from 'vue-router'
import Public from './views/Public.vue'
import Home from './views/Home.vue'
import About from './views/About.vue'
import Freetrial from './views/Freetrial.vue'
import Iot from './views/product/Iot.vue'
import Energy from './views/product/Energy.vue'
import Gateway from './views/product/Gateway.vue'
import Carbon from './views/product/Carbon.vue'
import Finance from './views/product/Finance.vue'
import Siot from './views/solution/Iot.vue'
import Smanage from './views/solution/Manage.vue'
import Szerocarbon from './views/solution/Zerocarbon.vue'
import Scarbon from './views/solution/Carbon.vue'
import Iron from './views/industry/Iron.vue'
import Park from './views/industry/Park.vue'
import Spin from './views/industry/Spin.vue'
import Hotel from './views/industry/Hotel.vue'
import Piot from './views/price/Iot.vue'
import Penergy from './views/price/Energy.vue'
import Pcarbon from './views/price/Carbon.vue'
import Phardware from './views/price/Hardware.vue'

const routes = [
	{
		path: '/',
		redirect: '/home'
	},
	{
		path: '/',
		component: Public,
		children: [
			{
				path: '/home',
				meta: {
					title: '领环leanloop-上海山楂互联网科技有限公司_工业物联网数据平台'
				},
				component: Home
			},
			{
				path: '/about',
				meta: {
					title: '关于我们_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: About
			},
			{
				path: '/freetrial',
				meta: {
					title: '免费试用_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Freetrial
			},
			{
				path: '/product/iot',
				meta: {
					title: 'IOT物联网平台_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Iot
			},
			{
				path: '/product/energy',
				meta: {
					title: '能源管理_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Energy
			},
			{
				path: '/product/gateway',
				meta: {
					title: '边缘网关_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Gateway
			},
			{
				path: '/product/carbon',
				meta: {
					title: '碳资产管理_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Carbon
			},
			{
				path: '/product/finance',
				meta: {
					title: '绿色金融与节能服务_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Finance
			},
			{
				path: '/solution/iot',
				meta: {
					title: '物联网基础数据服务中台_解决方案_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Siot
			},
			{
				path: '/solution/manage',
				meta: {
					title: '故障预测与健康管理_解决方案_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Smanage
			},
			{
				path: '/solution/zerocarbon',
				meta: {
					title: '零碳工厂与零碳园区_解决方案_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Szerocarbon
			},
			{
				path: '/solution/carbon',
				meta: {
					title: '区域绿色高质量发展与双碳平台_解决方案_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Scarbon
			},
			{
				path: '/industry/iron',
				meta: {
					title: '钢铁业_面向行业_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Iron
			},
			{
				path: '/industry/spin',
				meta: {
					title: '纺织业_面向行业_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Spin
			},
			{
				path: '/industry/park',
				meta: {
					title: '智慧园区_面向行业_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Park
			},
			{
				path: '/industry/hotel',
				meta: {
					title: '连锁酒店_面向行业_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Hotel
			},
			{
				path: '/price/iot',
				meta: {
					title: 'IOT物联网平台报价_产品报价_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Piot
			},
			{
				path: '/price/energy',
				meta: {
					title: '能源管理报价_产品报价_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Penergy
			},
			{
				path: '/price/carbon',
				meta: {
					title: '碳资产管理报价_产品报价_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Pcarbon
			},
			{
				path: '/price/hardware',
				meta: {
					title: '硬件报价_产品报价_领环leanloop-上海山楂互联网科技有限公司'
				},
				component: Phardware
			}
		]
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	if (to.matched && to.matched.length) {
		if (to.meta.title !== undefined) {
			document.title = `${to.meta.title}`
		}
		next()
	} else {
		next('/home')
	}
})

export default router
