<template>
	<div class="header flex" :style="{ top: show ? '0' : '-80px' }">
		<img src="../assets/logo_blue.png" alt="" class="logo" />
		<el-menu
			:default-active="'/'"
			class="menu"
			mode="horizontal"
			:router="true"
			active-text-color="#303133"
		>
			<el-menu-item index="/">首页</el-menu-item>
			<el-sub-menu index="2">
				<template #title>产品服务</template>
				<el-menu-item index="/product/iot">IOT物联网平台</el-menu-item>
				<el-menu-item index="/product/energy">能源管理</el-menu-item>
				<el-menu-item index="/product/gateway">边缘网关</el-menu-item>
				<el-menu-item index="/product/carbon">碳资产管理</el-menu-item>
				<el-menu-item index="/product/finance"
					>绿色金融与节能服务</el-menu-item
				>
			</el-sub-menu>
			<el-sub-menu index="3">
				<template #title>应用领域</template>
				<div class="sub_panel">
					<div class="list">
						<div class="title">解决方案</div>
						<div class="content">
							<router-link
								class="router_item"
								v-for="item in solution"
								:key="item.url"
								:to="item.url"
							>
								{{ item.text }}
							</router-link>
						</div>
					</div>
					<div class="list">
						<div class="title">面向行业</div>
						<div class="content">
							<router-link
								class="router_item"
								v-for="item in industry"
								:key="item.url"
								:to="item.url"
							>
								{{ item.text }}
							</router-link>
						</div>
					</div>
				</div>
			</el-sub-menu>
			<el-sub-menu index="4">
				<template #title>产品报价</template>
				<el-menu-item index="/price/iot"
					>IOT物联网平台报价</el-menu-item
				>
				<el-menu-item index="/price/energy">能源管理报价</el-menu-item>
				<el-menu-item index="/price/carbon"
					>碳资产管理报价</el-menu-item
				>
				<el-menu-item index="/price/hardware">硬件报价</el-menu-item>
			</el-sub-menu>
			<!-- <el-menu-item index="/about">关于我们</el-menu-item> -->
			<!-- <el-menu-item index="/about">开发者论坛</el-menu-item> -->
		</el-menu>

		<el-dropdown>
			<el-button type="primary" plain>登录</el-button>
			<template #dropdown>
				<el-dropdown-menu>
					<el-dropdown-item
						@click="
							toNewTab('https://www.leanloop.net/v4/view/#/login')
						"
						>IOT物联网平台</el-dropdown-item
					>
					<el-dropdown-item
						@click="toNewTab('https://cpn.leanloop.net/#/login')"
						>碳资产管理系统</el-dropdown-item
					>
				</el-dropdown-menu>
			</template>
		</el-dropdown>
		<router-link to="/freetrial">
			<el-button type="primary" class="mr-20 ml-20"
				>免费试用</el-button
			></router-link
		>
	</div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
export default {
	name: 'Header',
	props: {},
	setup() {
		const state = reactive({
			solution: [
				{
					text: '物联网基础数据服务中台',
					url: '/solution/iot'
				},
				{
					text: '故障预测与健康管理',
					url: '/solution/manage'
				},
				{
					text: '零碳工厂与零碳园区',
					url: '/solution/zerocarbon'
				},
				{
					text: '区域绿色高质量发展与双碳平台',
					url: '/solution/carbon'
				}
			],
			industry: [
				{
					text: '钢铁业',
					url: '/industry/iron'
				},
				{
					text: '纺织业',
					url: '/industry/spin'
				},
				{
					text: '智慧园区',
					url: '/industry/park'
				},
				{
					text: '连锁酒店',
					url: '/industry/hotel'
				}
			],
			oldScrollTop: 0, // 滚动前，滚动条距离文档顶部的距离
			show: true
		})
		const scrolling = () => {
			// 滚动条距离文档顶部的距离
			let scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop
			// 滚动条滚动的距离
			let scrollStep = scrollTop - state.oldScrollTop
			state.oldScrollTop = scrollTop
			if (scrollStep < 0) {
				// 向上
				state.show = true
			} else {
				if (document.documentElement.scrollTop < 80) {
					document.documentElement.scrollTop = 80
				}
				// 向下
				state.show = false
			}
		}
		const toNewTab = (url) => {
			window.open(url, '_blank')
		}
		onMounted(() => {
			window.addEventListener('scroll', scrolling)
		})

		return {
			...toRefs(state),
			toNewTab
		}
	}
}
</script>

<style scoped lang="scss">
.header {
	position: fixed;
	top: 0;
	height: 80px;
	width: 100%;
	align-items: center;
	background-color: #fff;
	transition: top 0.5s;
	z-index: 2000;
	box-shadow: 0px 0px 10px rgb(127 127 127 / 20%);
	.logo {
		margin: 0 40px;
	}
	.menu {
		width: 100%;
	}
}
</style>

<style lang="scss">
.el-menu--horizontal {
	border-bottom: none;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
	background-color: #fff;
}
.el-menu--horizontal > .el-menu-item.is-active {
	border-bottom: #fff;
}
.el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title {
	border-bottom: #fff;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
	color: #409eff !important;
}
.sub_panel {
	width: 600px;
	display: flex;
	box-sizing: border-box;
	padding: 10px 20px;
	.list {
		width: 50%;
		.title {
			font-size: 16px;
			border-bottom: 1px solid #e4e7ed;
			line-height: 36px;
			height: 36px;
		}
		.content {
			height: auto;
			margin-top: 10px;
			.router_item {
				display: block;
				line-height: 36px;
				height: 36px;
				font-size: 14px;
				cursor: pointer;
				color: #303133;
				&:hover {
					color: #409eff;
				}
			}
		}
	}
	.list + .list {
		margin-left: 40px;
	}
}
</style>
