<template>
	<div>
		<div class="banner">
			<div class="title text-center">钢铁业</div>
			<div class="desc text-center">助力钢铁企业“百万设备上网”</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">解决方案</div>
				<div class="en">Solutions</div>
			</div>
		</div>
		<LeftRightTree :treeData="tree"></LeftRightTree>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">精选案例</div>
				<div class="en">Selected Cases</div>
			</div>
			<div class="common_case">
				<img src="../../assets/industry/iron1.png" alt="" />
				<div class="text">
					<div class="bold">
						某钢铁企业是世界500强央企下的骨干子公司，拥有员工近3万人，拥有专业设备近2.5万套，具备冶金全产业链一体化运营服务能力。
						项目实施后，已实现：
					</div>
					<div>
						<ul>
							<li>
								IOT主平台部署在本部研究院中心机房，进行数据分析与算法训练；
							</li>
							<li>
								IOT子平台和智慧运维系统部署在71个子厂区的本地监控机房，在边缘侧进行故障预警，并与主平台实现数据缓存与同步；
							</li>
							<li>构建多个时域模型与系统模型，优化生产工艺。</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">相关产品</div>
				<div class="en">Related Products</div>
			</div>
		</div>
		<Carousel :cardData="cards"></Carousel>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Carousel from '../../components/Carousel.vue'
import LeftRightTree from '../../components/LeftRightTree.vue'
export default {
	components: {
		Carousel,
		LeftRightTree
	},
	setup() {
		const state = reactive({
			cards: [
				{
					text: 'IOT物联网平台',
					desc: '工业级物联网数据集成与数据资源管理',
					url: '/product/iot',
					img: require('../../assets/bc/24.png')
				},
				{
					text: '边缘网关',
					desc: '设备侧的边缘计算PaaS平台',
					url: '/product/gateway',
					img: require('../../assets/bc/25.png')
				},
				{
					text: '故障预测与健康管理',
					desc: '发现早期故障特征、预测故障发展趋势',
					url: '/solution/manage',
					img: require('../../assets/bc/10.png')
				}
			],
			tree: [
				{
					title: '故障预测与健康管理（PHM）',
					list: [
						'涵盖行车、皮带机、退火炉、堆取料机、脱硫脱硝、冶金设备传动、修磨机、连铸精整区域设备七类设备类型的数据采集与故障预测'
					],
					img: require('../../assets/industry/iron2.png')
				},
				{
					title: '工艺优化与精准感知',
					list: [
						'提供转炉内气 / 高温熔体两相流动行为优化、电炉控制系统优化、转炉终点过氧化控制精准感知技术'
					],
					img: require('../../assets/industry/iron3.png')
				}
			]
		})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style scoped lang="scss">
.banner {
	background-image: url(../../assets/bc/13.png);
}
</style>
