<template>
	<div>
		<div class="banner">
			<div class="title text-center">碳资产管理系统</div>
			<div class="desc text-center">
				协助管理者盘查摸底谁在排放、排放源是什么、排放了多少的核心问题，<br />是企业实施自查和政府管理区域碳排放的精准工具，为企业节省人力成本，为政府节省管理成本。
			</div>
		</div>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">服务体系</div>
				<div class="en">Service System</div>
			</div>
			<div class="titles">
				<div class="box">
					<div>政策制度体系</div>
					<div>组织保障体系</div>
					<div>标准规范体系</div>
					<div>网络安全体系</div>
				</div>
			</div>
			<div class="center_img">
				<img src="../../assets/product/carbon6.png" alt=""  style="width:800px;"/>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">核心能力</div>
				<div class="en">Core Competences</div>
			</div>
		</div>
		<LeftRightTree :treeData="tree"></LeftRightTree>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">应用场景</div>
				<div class="en">Application Scenarios</div>
			</div>
		</div>
		<Carousel :cardData="cards"></Carousel>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Carousel from '../../components/Carousel.vue'
import LeftRightTree from '../../components/LeftRightTree.vue'
export default {
	components: {
		Carousel,
		LeftRightTree
	},
	setup() {
		const state = reactive({
			cards: [
				{
					text: '零碳工厂与零碳园区',
					desc: '以节能服务、碳资产管理赋能零碳工厂与零碳园区建设',
					url: '/solution/zerocarbon',
					img: require('../../assets/bc/11.png')
				},
				{
					text: '区域绿色高质量发展与双碳平台',
					desc: '数据是基础、管理是手段、零碳是目标',
					url: '/solution/carbon',
					img: require('../../assets/bc/12.png')
				},
				{
					text: '纺织业',
					desc: '纺织设备数据联网与节能控制优化',
					url: '/industry/spin',
					img: require('../../assets/bc/14.png')
				}
			],
			tree: [
				{
					title: '遵循国家标准的核算方法、报告与流程',
					list: [
						'依据行业企业温室气体核算方法与报告指南要求，内置行业核算模板',
						'企业侧的碳报告与配额管理',
						'第三方机构侧的碳核查管理',
						'行政管理侧的审核与配额分配'
					],
					img: require('../../assets/product/carbon1.png')
				},
				{
					title: '权威、完备的碳排放因子库',
					list: [
						'按国家、区域、行业管理排放因子与缺省值',
						'支持自定义排放因子数字模型',
						'内置常用的排放因子单位，复杂单位在线转换'
					],
					img: require('../../assets/product/carbon2.png')
				},
				{
					title: '灵活对接三方数据',
					list: [
						'打通数据孤岛',
						'提供Restful规范的数据访问接口',
						'接收第三方系统上报的数据',
						'向第三方系统报送标准碳数据'
					],
					img: require('../../assets/product/carbon3.png')
				},
				{
					title: '碳数据可视化与指标体系',
					list: [
						'多维度碳数据分析',
						'BI可视化与GIS技术结合，一目了然',
						'高排放及时预警',
						'建立碳指标综合评价体系'
					],
					img: require('../../assets/product/carbon3.png')
				},
				{
					title: '可扩展的碳数据应用场景',
					list: ['碳积分', '碳账户', '碳信誉'],
					img: require('../../assets/product/carbon3.png')
				}
			]
		})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style scoped lang="scss">
.banner {
	background-image: url(../../assets/bc/21.png);
}
</style>
