<template>
	<div>
		<div class="banner">
			<div class="title text-center">边缘网关</div>
			<div class="desc text-center">
				部署于设备现场，集数据采集、通讯、策略引擎、AI推理于一体的边缘计算PaaS平台
			</div>
		</div>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">硬件报价</div>
				<div class="en">Product quotation</div>
			</div>
		</div>
		<div class="hardware-price">
			<div class="whole-table">
				<div class="toptitle">
					<span class="toptitlebor"></span>
					<p>
						边缘网关
						<span style="color: #0388fc; font-size: 16px">①</span>
					</p>
				</div>

				<!-- <el-affix :offset="0"> -->
				<div class="table-row flex mt10 mb10">
					<div class="row-one ft16 bold mr3 w200">
						<span class="circular"></span>
						类别
					</div>
					<div class="flex rightTitle">
						<div class="row-one ft16 bold mr3 w160">
							<span class="circular"></span>
							功能
						</div>
						<div class="row-three ft16 bold mr3 w400">
							<span class="circular"></span>
							一型边缘网关
						</div>
						<div class="row-three ft16 bold mr3 w400">
							<span class="circular"></span>
							二型信创网关
						</div>
					</div>
				</div>
				<!-- </el-affix> -->
				<div
					class="table-row flex mb3 flexw mb3"
					v-for="item in tableData"
					:key="item.title"
				>
					<div class="row-one ft16 bgcf bold mr3 leftTitle w200">
						<p
							class="title-text"
							:style="{ 'border-left-color': item.borCol }"
						>
							{{ item.title }}
							<span
								style="color: #0388fc; margin-left: 3px"
								v-if="item.titleIcon"
							>
								{{ item.titleIcon }}</span
							>
						</p>
					</div>
					<div class="wAll">
						<div
							class="row-two ft14 flex"
							v-for="item1 in item.children"
							:key="item1.one"
						>
							<div class="row-one bgcf mr3 w160">
								<p>
									{{ item1.one }}
									<span
										style="color: #0388fc"
										v-if="item1.icon"
										>{{ item1.icon }}</span
									>
								</p>
								<p v-if="item1.oneTitle">
									{{ item1.oneTitle }}
								</p>
							</div>
							<div class="row-one bgcf mr3 w400">
								<p>
									{{ item1.two }}
									<span
										style="color: #0388fc"
										v-if="item1.numIconTwo"
										>{{ item1.numIconTwo }}</span
									>
								</p>
								<p v-if="item1.twoText">{{ item1.twoText }}</p>
								<el-icon v-if="item1.twoIcon === 'd'"
									><Check
								/></el-icon>
								<el-icon v-if="item1.twoIcon === 'c'"
									><Close
								/></el-icon>
							</div>
							<div
								class="row-one bgcf mr3 w400"
								v-if="!item1.custStyle"
							>
								<p>{{ item1.three }}</p>
								<p v-if="item1.threeText">
									{{ item1.threeText }}
								</p>
								<el-icon v-if="item1.threeIcon === 'd'"
									><Check
								/></el-icon>
								<el-icon v-if="item1.threeIcon === 'c'"
									><Close
								/></el-icon>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 试验箱 -->
		<div class="hardware-price" style="padding-top: 50px">
			<div class="whole-table">
				<div class="toptitle">
					<span class="toptitlebor"></span>
					<p>
						试验箱
						<span style="color: #0388fc; font-size: 16px">③</span>
					</p>
				</div>
				<!-- <el-affix :offset="0"> -->
				<div class="table-row flex mt10 mb10">
					<div class="row-one ft16 bold mr3 w200">
						<span class="circular"></span>
						类别
					</div>
					<div class="flex rightTitle">
						<div class="row-one ft16 bold mr3 w160">
							<span class="circular"></span>
							功能
						</div>
						<div class="row-three ft16 bold mr3 w850">
							<span class="circular"></span>
							IOT试验箱
						</div>
					</div>
				</div>
				<!-- </el-affix> -->
				<div
					class="table-row flex mb3 flexw mb3"
					v-for="item in tableData2"
					:key="item.title"
				>
					<div class="row-one ft16 bgcf bold mr3 leftTitle w200">
						<p
							class="title-text"
							:style="{ 'border-left-color': item.borCol }"
						>
							{{ item.title }}
							<span
								style="color: #0388fc; margin-left: 3px"
								v-if="item.titleIcon"
							>
								{{ item.titleIcon }}</span
							>
						</p>
					</div>
					<div class="wAll">
						<div
							class="row-two ft14 flex"
							v-for="item1 in item.children"
							:key="item1.one"
						>
							<div class="row-one bgcf mr3 w160">
								<p>
									{{ item1.one }}
									<span
										style="color: #0388fc"
										v-if="item1.icon"
										>{{ item1.icon }}</span
									>
								</p>
								<p v-if="item1.oneTitle">
									{{ item1.oneTitle }}
								</p>
							</div>
							<div class="row-one bgcf mr3 w850">
								<p>
									{{ item1.two }}
									<span
										style="color: #0388fc"
										v-if="item1.numIconTwo"
										>{{ item1.numIconTwo }}</span
									>
								</p>
								<p v-if="item1.twoText">{{ item1.twoText }}</p>
								<el-icon v-if="item1.twoIcon === 'd'"
									><Check
								/></el-icon>
								<el-icon v-if="item1.twoIcon === 'c'"
									><Close
								/></el-icon>
							</div>
						</div>
					</div>
				</div>
				<h4 style="margin-top:30px;">试验箱-示例图</h4>
				<img src="../../assets/shiyanxiang.jpg" alt="" class="tupic" />
				<div class="explain bold" style="margin-top: 20px">说明</div>
				<p class="ft14">
					①：指部署在用户现场的数据采集网关，除已经提供的型号外，还可根据用户需求提供选型；
				</p>
				<p class="ft14">
					②：可至<span class="onTrial" @click="goTrial">边缘网关</span
					>了解更多信息；
				</p>
				<p class="ft14">
					③：用于数据采集、调试、专家策略等测试与演示场景，可根据用户需求定制。
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
export default {
	components: {},
	setup() {
		const state = reactive({
			tableData: [
				{
					title: '报价',
					borCol: '#0388FC',
					children: [
						{
							one: '购置价格',
							two: '5,800元/台起，具体联系销售',
							three: '8,800元/台起，具体联系销售'
						},
						{
							one: '质保服务',
							two: '1年',
							three: '1年'
						}
					]
				},
				{
					title: '硬件规格',
					borCol: '#886EFE',
					children: [
						{
							one: '品牌型号',
							icon: '',
							oneTitle: '',
							two: 'LL-GATE1000',
							numIconTwo: '②',
							three: 'LL-GATE2000'
						},
						{
							one: '操作系统',
							icon: '',
							oneTitle: '',
							two: 'Linux/ARM64',
							three: 'Linux/HYGON3000'
						},
						{
							one: '硬件接口',
							icon: '',
							oneTitle: '',
							two: 'RS485*2,LAN*2,WIFI,USB*3',
							three: 'RS485*2,LAN*1,WIFI,USB*8'
						},
						{
							one: '连接网络',
							icon: '',
							oneTitle: '',
							two: '4G/5G网络（选配）',
							three: '4G/5G网络（选配）'
						}
					]
				}
			],
			tableData2: [
				{
					title: '报价',
					borCol: '#0388FC',
					children: [
						{
							one: '购置价格',
							two: '15,000元/台'
						},
						{
							one: '质保服务',
							two: '1年'
						}
					]
				},
				{
					title: '基础物料',
					borCol: '#886EFE',
					children: [
						{
							one: '品牌型号',
							icon: '',
							oneTitle: '',
							two: 'LL-LAB5000'
						},
						{
							one: '采集器',
							icon: '',
							oneTitle: '',
							two: '5'
						},
						{
							one: '输出量',
							icon: '',
							oneTitle: '',
							two: '继电器*4，开关量*8，灯光*N，声音*1'
						},
						{
							one: '输入量',
							icon: '',
							oneTitle: '',
							two: '开关量*8，模拟量*8'
						},
						{
							one: '断路器',
							icon: '',
							oneTitle: '',
							two: '2'
						},
						{
							one: '单相电表',
							icon: '',
							oneTitle: '',
							two: '1'
						},
						{
							one: '光照传感器',
							icon: '',
							oneTitle: '',
							two: '1'
						},
						{
							one: '温湿度传感器',
							icon: '',
							oneTitle: '',
							two: '1'
						},
						{
							one: '人体红外探测器',
							icon: '',
							oneTitle: '',
							two: '1'
						},
						{
							one: '其他物料需求',
							icon: '',
							oneTitle: '',
							two: '联系销售'
						}
					]
				}
			]
		})

		const router = useRouter()
		const goTrial = () => {
			router.push('/product/gateway')
		}
		return {
			...toRefs(state),
			goTrial
		}
	}
}
</script>

<style scoped lang="scss">
.banner {
	background-image: url(../../assets/bc/20.png);
}
.ft16 {
	font-size: 16px;
}
.ft14 {
	font-size: 14px;
}
.bold {
	font-weight: 700;
}
.flex {
	display: flex;
}
.flexw {
	flex-wrap: wrap;
}
.mb10 {
	margin-bottom: 10px;
}
.mt10 {
	margin-top: 10px;
}
.mr3 {
	margin-right: 3px;
}
.mb3 {
	margin-bottom: 3px;
}
.w160 {
	width: 20% !important;
}

.rightTitle {
	width: calc(100% - 15% - 3px);
}
.toptitle {
	font-size: 20px;
	padding-left: 6px;
	position: relative;
	margin-bottom: 30px;
	.toptitlebor {
		position: absolute;
		height: 20px;
		width: 2px;
		background-color: #333;
		left: 6px;
		top: 5px;
	}
	p {
		padding-left: 10px;
	}
}
.hardware-price {
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
	.whole-table {
		width: 100%;
		padding: 0 50px;
	}
	.row-one {
		padding-left: 15px;
		box-sizing: border-box;
		width: 150px;
	}
	.row-three {
		padding-left: 15px;
		box-sizing: border-box;
		width: 150px;
	}
	.row-four {
		padding-left: 15px;
		box-sizing: border-box;
		width: 200px;
	}
	.w200 {
		// width: 200px;
		width: 15%;
	}
	.w400 {
		width: 425px;
		width: calc((100% - 20%) / 2);
	}
	.w850 {
		width: 80%;
	}
	.wAll {
		// width: calc(100% - 206px);
		width: calc(100% - 15% - 3px);
		.row-two {
			margin-bottom: 3px;
			&:nth-last-child(1) {
				margin-bottom: 0;
			}
		}
	}
	.row-two {
		.row-one {
			padding: 20px;
			box-sizing: border-box;
		}
	}
	.bgcf {
		background-color: #fff;
	}
	.circular {
		display: inline-block;
		width: 7px;
		height: 7px;
		border-radius: 50%;
		background-color: #0388fc;
		position: relative;
		top: -1px;
	}
	.opac {
		opacity: 0;
	}
	.leftTitle {
		padding: 6px;
		box-sizing: border-box;
		.title-text {
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: 2px solid #0388fc;
			height: 100%;
		}
	}
	.borLeft {
		border-left-color: #886efe !important;
	}
	.onTrial {
		cursor: pointer;
		color: #0388fc;
	}
	.tupic {
		width: 100%;
		margin-top: 20px;
	}
}
</style>
