<template>
	<div>
		<div class="banner">
			<div class="title text-center">碳资产管理系统</div>
			<div class="desc text-center">
				协助管理者盘查摸底谁在排放、排放源是什么、排放了多少的核心问题，<br />是企业实施自查和政府管理区域碳排放的精准工具，为企业节省人力成本，为政府节省管理成本。
			</div>
		</div>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">碳资产管理 产品报价</div>
				<div class="en">Product quotation</div>
			</div>
		</div>
		<div class="carbon-price">
			<div class="whole-table">
				<div class="table-row flex mt10 mb10">
					<div class="row-one ft16 bold mr3 w200">
						<span class="circular"></span>
						类别
						<el-icon
							class="drrowRight"
							:class="[allOpen ? 'upIcon' : '']"
							@click="allCheck"
							><DArrowRight
						/></el-icon>
					</div>
					<div class="flex rightTitleC">
						<div class="row-one ft16 bold mr3 w160">
							<span class="circular"></span>
							功能
						</div>
						<div class="row-three ft16 bold mr3 w400">
							<span class="circular"></span>
							试用版
						</div>
						<div class="row-three ft16 bold mr3 w400">
							<span class="circular"></span>
							私有部署版
						</div>
						<div class="row-three ft16 bold mr3 w400">
							<span class="circular"></span>
							SaaS版
						</div>
					</div>
				</div>
				<div
					class="table-row flex mb3 flexw mb3"
					v-for="(item, i) in tableData"
					:key="item.title"
				>
					<div
						class="row-one ft16 bgcf bold mr3 leftTitle w200"
						v-if="item.isOpen"
					>
						<p
							class="title-text"
							:style="{ 'border-left-color': item.borCol }"
							@click="toggleHide(item)"
						>
							{{ item.title }}
							<span
								style="color: #0388fc; margin-left: 3px"
								v-if="item.titleIcon"
							>
								{{ item.titleIcon }}</span
							>
							<el-icon v-if="i > 2" class="drrowRight upIcon"
								><DArrowRight
							/></el-icon>
						</p>
					</div>
					<div class="wAll" v-if="item.isOpen">
						<div
							class="row-two ft14 flex"
							v-for="item1 in item.children"
							:key="item1.one"
						>
							<div class="row-one bgcf mr3 w160">
								<p>
									{{ item1.one }}
									<span
										style="color: #0388fc"
										v-if="item1.icon"
										>{{ item1.icon }}</span
									>
								</p>
								<p v-if="item1.oneTitle">
									{{ item1.oneTitle }}
								</p>
							</div>
							<div class="row-one bgcf mr3 w400">
								<p>{{ item1.two }}</p>
								<p v-if="item1.twoText">{{ item1.twoText }}</p>
								<el-icon v-if="item1.twoIcon === 'd'"
									><Check
								/></el-icon>
								<el-icon v-if="item1.twoIcon === 'c'"
									><Close
								/></el-icon>
							</div>
							<div
								class="row-one bgcf mr3 w400"
								v-if="!item1.custStyle"
							>
								<p>{{ item1.three }}</p>
								<p v-if="item1.threeText">
									{{ item1.threeText }}
								</p>
								<el-icon v-if="item1.threeIcon === 'd'"
									><Check
								/></el-icon>
								<el-icon v-if="item1.threeIcon === 'c'"
									><Close
								/></el-icon>
							</div>
							<div
								class="row-one bgcf mr3 w400"
								v-if="!item1.custStyle"
							>
								<p>{{ item1.four }}</p>
								<p v-if="item1.fourText">
									{{ item1.fourText }}
								</p>
								<el-icon v-if="item1.fourIcon === 'd'"
									><Check
								/></el-icon>
								<el-icon v-if="item1.fourIcon === 'c'"
									><Close
								/></el-icon>
							</div>
						</div>
					</div>
					<div v-if="!item.isOpen" class="hideClass">
						<p @click="toggleHide(item)">
							{{ item.title }}
							<el-icon class="drrowRight"
								><DArrowRight
							/></el-icon>
						</p>
					</div>
				</div>
				<div class="explain bold" style="margin-top: 20px"></div>
				<p class="ft14">
					申请“
					<span class="onTrial" @click="goTrial">免费试用</span
					>”，即刻获取碳资产管理系统免费账号。
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useRouter } from 'vue-router'
export default {
	components: {},
	setup() {
		const state = reactive({
			tableData: [
				{
					isOpen: true,
					title: '报价',
					borCol: '#0388FC',
					children: [
						{
							one: '初始购置价格',
							two: '免费',
							three: '9,000元/项目',
							four: '200元/账号，年'
						},
						{
							one: '订阅年费',
							two: '免费',
							three: '15%（第二年起）',
							four: '200元/账号，年'
						},
						{
							one: '使用期技术支持',
							oneTitle: '（超时服务）',
							two: '-',
							three: '300元/小时',
							four: '300元/小时'
						},
						{
							one: '培训服务',
							oneTitle: '（超次服务）',
							two: '-',
							three: '300元/次',
							four: '300元/次'
						},
						{
							one: '短信服务',
							oneTitle: '',
							two: '-',
							three: '0.05元/条',
							four: '0.05元/条'
						}
					]
				},
				{
					isOpen: true,
					title: '用户类型',
					borCol: '#886EFE',
					children: [
						{
							one: '',
							icon: '',
							oneTitle: '',
							two: '个人学习',
							three: '区域政府/集团企业',
							four: '培训机构/小型项目'
						}
					]
				},
				{
					isOpen: true,
					title: '应用规模',
					borCol: '#0388FC',
					children: [
						{
							one: '初始数量',
							icon: '',
							oneTitle: '',
							two: '人员数量：3人',
							twoText: '报告数量：20份/自然年',
							three: '人员数量：10人',
							threeText: '报告数量：20份/自然年',
							four: '人员数量：按购买量',
							fourText: '报告数量：20份/自然年'
						}
					]
				},
				{
					isOpen: false,
					title: '平台基础功能',
					titleIcon: '',
					borCol: '#886EFE',
					children: [
						{
							one: '组织管理',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd'
						},
						{
							one: '人员管理',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd'
						},
						{
							one: '权限管理',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd'
						},
						{
							one: '注册企业',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd'
						},
						{
							one: '组织注册审核',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd'
						}
					]
				},
				{
					isOpen: false,
					title: '碳计算',
					borCol: '#0388FC',
					children: [
						{
							one: '碳排放因子计算',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd'
						},
						{
							one: '碳核查报告',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd'
						}
					]
				},
				{
					isOpen: false,
					title: '扩展能力',
					borCol: '#886EFE',
					children: [
						{
							one: '能耗数据自动采集',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							three: '',
							threeIcon: 'd',
							fourIcon: 'c'
						}
					]
				},
				{
					isOpen: false,
					title: '软件部署',
					borCol: '#0388FC',
					children: [
						{
							one: '部署模式',
							icon: '',
							oneTitle: '',
							two: '公有云',
							twoIcon: '',
							three: '私有部署',
							threeIcon: '',
							four: '公有云'
						}
					]
				},
				{
					isOpen: false,
					title: '售后支持',
					borCol: '#886EFE',
					children: [
						{
							one: '呼叫响应',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							three: '5*8小时',
							threeIcon: '',
							four: '5*8小时'
						},
						{
							one: '技术支持',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							three: '2小时',
							threeIcon: '',
							four: '2小时'
						},
						{
							one: '培训支持',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							three: '1次免费在线培训',
							threeIcon: '',
							four: '1次免费在线培训'
						},
						{
							one: '产品升级',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							three: '订阅期内免费升级',
							threeIcon: '',
							four: '订阅期内免费升级'
						},
						{
							one: '数据备份',
							icon: '',
							oneTitle: '',
							two: '每周定时清理数据',
							twoIcon: '',
							three: '',
							threeIcon: 'c',
							four: '数据备份、导出，无供应商锁定'
						}
					]
				},
				{
					isOpen: false,
					title: '开发支持',
					borCol: '#0388FC',
					children: [
						{
							one: '论坛支持',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'd',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd'
						},
						{
							one: '邮件支持',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd'
						},
						{
							one: '电话支持',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							three: '5*8小时',
							threeIcon: '',
							fourIcon: '',
							four: '5*8小时'
						},
						{
							one: '企业微信',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							three: '',
							threeIcon: 'd',
							fourIcon: 'd'
						},
						{
							one: '二次开发',
							icon: '',
							oneTitle: '',
							two: '',
							twoIcon: 'c',
							three: '联系销售',
							threeIcon: '',
							fourIcon: 'c'
						}
					]
				}
			],
			allOpen: computed(() =>
				state.tableData.every((item) => item.isOpen)
			)
		})
		const router = useRouter()
		const goTrial = () => {
			router.push('/freetrial')
		}
		const allCheck = () => {
			let bool = !state.allOpen
			state.tableData.forEach((item, i) => {
				if (i > 2) {
					item.isOpen = bool
				}
			})
		}
		// 切换显示隐藏
		const toggleHide = (row) => {
			row.isOpen = !row.isOpen
		}
		return {
			...toRefs(state),
			goTrial,
			toggleHide,
			allCheck
		}
	}
}
</script>

<style scoped lang="scss">
.drrowRight {
	transform: rotate(90deg);
	position: relative;
	top: 2px;
	font-size: 16px;
	margin-left: 3px;
	cursor: pointer;
}
.upIcon {
	transform: rotate(-90deg);
}
.banner {
	background-image: url(../../assets/bc/21.png);
}
.ft16 {
	font-size: 16px;
}
.ft14 {
	font-size: 14px;
}
.bold {
	font-weight: 700;
}
.flex {
	display: flex;
}
.flexw {
	flex-wrap: wrap;
}
.mb10 {
	margin-bottom: 10px;
}
.mt10 {
	margin-top: 10px;
}
.mr3 {
	margin-right: 3px;
}
.mb3 {
	margin-bottom: 3px;
}
.w160 {
	width: 20% !important;
}
.rightTitleC {
	width: calc(100% - 15% - 3px);
}

.carbon-price {
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
	.whole-table {
		width: 100%;
		padding: 0 50px;
	}
	.row-one {
		padding-left: 15px;
		box-sizing: border-box;
	}
	.row-three {
		padding-left: 15px;
		box-sizing: border-box;
	}
	.w200 {
		// width: 200px;
		width: 15%;
	}
	.w400 {
		// width: 275px;
		width: calc((100% - 20% - 9px) / 3);
	}
	.wAll {
		width: calc(100% - 15% - 3px);
		.row-two {
			margin-bottom: 3px;
			&:nth-last-child(1) {
				margin-bottom: 0;
			}
			.w400 {
				&:nth-last-child(1) {
					margin-right: 0;
				}
			}
		}
	}
	.row-two {
		.row-one {
			padding: 20px;
			box-sizing: border-box;
		}
	}
	.bgcf {
		background-color: #fff;
	}
	.circular {
		display: inline-block;
		width: 7px;
		height: 7px;
		border-radius: 50%;
		background-color: #0388fc;
		position: relative;
		top: -1px;
	}
	.opac {
		opacity: 0;
	}
	.leftTitle {
		padding: 6px;
		box-sizing: border-box;
		.title-text {
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: 2px solid #0388fc;
			height: 100%;
		}
	}
	.borLeft {
		border-left-color: #886efe !important;
	}
	.onTrial {
		cursor: pointer;
		color: #0388fc;
	}
	.hideClass {
		width: 100%;
		background-color: #fff;
		padding: 20px;
		text-align: center;
	}
}
</style>
