<template>
	<div>
		<div class="banner">
			<div class="title text-center">物联网基础数据服务中台</div>
			<div class="desc text-center">
				基于微服务与前后端分离的开发模式，<br />搭建物联网数据服务中台，提供标准API，与业务系统完全解耦。
			</div>
		</div>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">方案架构</div>
				<div class="en">Solution Schema</div>
			</div>
			<div class="center_img">
				<img src="../../assets/solution/iot4.png" alt=""  style="width:980px;"/>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">方案优势</div>
				<div class="en">Solution Advantages</div>
			</div>
		</div>
		<LeftRightTree :treeData="tree"></LeftRightTree>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">相关产品</div>
				<div class="en">Related Products</div>
			</div>
		</div>
		<Carousel :cardData="cards"></Carousel>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Carousel from '../../components/Carousel.vue'
import LeftRightTree from '../../components/LeftRightTree.vue'
export default {
	components: {
		Carousel,
		LeftRightTree
	},
	setup() {
		const state = reactive({
			cards: [
				{
					text: 'IOT物联网平台',
					desc: '工业级物联网数据集成与数据资源管理',
					url: '/product/iot',
					img: require('../../assets/bc/24.png')
				},
				{
					text: '边缘网关',
					desc: '设备侧的边缘计算PaaS平台',
					url: '/product/gateway',
					img: require('../../assets/bc/25.png')
				},
				{
					text: '钢铁业',
					desc: '助力钢铁企业“百万设备上网”',
					url: '/industry/iron',
					img: require('../../assets/bc/13.png')
				}
			],
			tree: [
				{
					title: '标准数据服务',
					list: [
						'与数据消费方、业务应用方之间采用标准协议，用户无需担心被品牌绑定',
						'无缝对接第三方数据中台与应用系统',
						'内置多维指标预计算'
					],
					img: require('../../assets/solution/iot1.png')
				},
				{
					title: '高频数据处理',
					list: [
						'具备高频数据、超大并发、高可靠性的工业物联网大数据管理能力'
					],
					img: require('../../assets/solution/iot2.png')
				},
				{
					title: '以需定量计费',
					list: [
						'多租户软件架构',
						'根据设备采集点位数量，提供软件授权'
					],
					img: require('../../assets/solution/iot3.png')
				}
			]
		})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style scoped lang="scss">
.banner {
	background-image: url(../../assets/bc/23.png);
}
li {
	list-style: disc;
}
</style>
