<template>
	<div>
		<div class="banner">
			<div class="title text-center">纺织业</div>
			<div class="desc text-center">纺织设备数据联网与节能控制优化</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">解决方案</div>
				<div class="en">Solutions</div>
			</div>
		</div>
		<LeftRightTree :treeData="tree"></LeftRightTree>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">精选案例</div>
				<div class="en">Selected Cases</div>
			</div>
			<div class="common_case">
				<img src="../../assets/industry/spin1.png" alt="" />
				<div class="text">
					<div class="bold">
						某纺织企业是一家主板上市公司，总建筑面积16万平方米，年产高端针织面料5万吨。
					</div>
					<div class="bold">
						领环为该企业的高端服饰面料智能工厂提供基于工业IOT物联网操作系统的智慧能源管控平台，实现水-电-蒸汽-空压整体能源网络节能优化与生产节拍碳足迹分析。
					</div>
				</div>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">相关产品</div>
				<div class="en">Related Products</div>
			</div>
		</div>
		<Carousel :cardData="cards"></Carousel>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Carousel from '../../components/Carousel.vue'
import LeftRightTree from '../../components/LeftRightTree.vue'
export default {
	components: {
		Carousel,
		LeftRightTree
	},
	setup() {
		const state = reactive({
			cards: [
				{
					text: 'IOT物联网平台',
					desc: '工业级物联网数据集成与数据资源管理',
					url: '/product/iot',
					img: require('../../assets/bc/24.png')
				},
				{
					text: '能源管理',
					desc: '分项计量与多能监测',
					url: '/product/energy',
					img: require('../../assets/bc/3.png')
				},
				{
					text: '边缘网关',
					desc: '设备侧的边缘计算PaaS平台',
					url: '/product/gateway',
					img: require('../../assets/bc/25.png')
				},
				{
					text: '碳资产管理',
					desc: '盘查摸底碳排放源与排放量',
					url: '/product/carbon',
					img: require('../../assets/bc/2.png')
				}
			],
			tree: [
				{
					title: '能耗分项计量',
					list: [
						'对企业边界内的水、电、燃气、热力进行分项计量',
						'提供集抄表、收费、账单、能耗统计、限额管理、故障告警、需量预警等服务'
					],
					img: require('../../assets/industry/spin2.png')
				},
				{
					title: '工艺系统数据联网与节能优化',
					list: [
						'涉及到蒸汽生产、高温烟气余热利用、高温污水余热利用、空压机能效管理、中水工艺管理、光伏发电系统等'
					],
					img: require('../../assets/industry/spin3.png')
				},
				{
					title: '产品碳足迹',
					list: ['基于生产节拍的碳核算数据，用于企业产品碳足迹计算'],
					img: require('../../assets/industry/spin4.png')
				}
			]
		})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style scoped lang="scss">
.banner {
	background-image: url(../../assets/bc/14.png);
}
</style>
