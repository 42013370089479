<template>
	<div>
		<div class="banner">
			<div class="title text-center">零碳工厂与零碳园区</div>
			<div class="desc text-center">
				以节能服务、碳资产管理赋能零碳工厂与零碳园区建设。
			</div>
		</div>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">方案流程</div>
				<div class="en">Solution Process</div>
			</div>
			<div class="center_img">
				<img src="../../assets/solution/zerocarbon4.png" alt=""  style="width:950px;"/>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">方案优势</div>
				<div class="en">Solution Advantages</div>
			</div>
		</div>
		<LeftRightTree :treeData="tree"></LeftRightTree>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">相关产品</div>
				<div class="en">Related Products</div>
			</div>
		</div>
		<Carousel :cardData="cards"></Carousel>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Carousel from '../../components/Carousel.vue'
import LeftRightTree from '../../components/LeftRightTree.vue'
export default {
	components: {
		Carousel,
		LeftRightTree
	},
	setup() {
		const state = reactive({
			cards: [
				{
					text: 'IOT物联网平台',
					desc: '工业级物联网数据集成与数据资源管理',
					url: '/product/iot',
					img: require('../../assets/bc/24.png')
				},
				{
					text: '能源管理',
					desc: '分项计量与多能监测',
					url: '/product/energy',
					img: require('../../assets/bc/3.png')
				},
				{
					text: '边缘网关',
					desc: '设备侧的边缘计算PaaS平台',
					url: '/product/gateway',
					img: require('../../assets/bc/25.png')
				},
				{
					text: '碳资产管理',
					desc: '盘查摸底碳排放源与排放量',
					url: '/product/carbon',
					img: require('../../assets/bc/2.png')
				},
				{
					text: '绿色金融与节能服务',
					desc: '与用户共享节能投资收益',
					url: '/product/finance',
					img: require('../../assets/bc/17.png')
				}
			],
			tree: [
				{
					title: '资源共享',
					list: [
						'优质的碳交易与碳金融上下游资源',
						'在零碳项目规划期，基于联合科研模式实现多方合作破冰'
					],
					img: require('../../assets/solution/zerocarbon1.png')
				},
				{
					title: '系统优化管理',
					list: [
						'综合能源系统优化',
						'余热回收系统优化',
						'低温制冷系统优化',
						'AI节能控制优化'
					],
					img: require('../../assets/solution/zerocarbon2.png')
				},
				{
					title: '扎实的交付能力',
					list: [
						'二十余年节能服务经验',
						'早于2013年主导建设上海市首个近零碳园区',
						'具有绿色融资、技术服务与节能运维能力'
					],
					img: require('../../assets/solution/zerocarbon3.png')
				}
			]
		})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style scoped lang="scss">
.banner {
	background-image: url(../../assets/bc/11.png);
}
</style>
