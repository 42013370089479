<template>
	<div class="home">
		<div class="home_top">
			<div class="title">工业物联网数据平台</div>
			<div class="desc">标准 · 物联 · 共享</div>
		</div>
		<div
			class="item"
			v-for="item in items"
			:key="item.url"
			:style="{ backgroundImage: 'url(' + item.img + ')' }"
		>
			<div class="title">{{ item.title }}</div>
			<div class="desc">{{ item.desc }}</div>
			<div>
				<router-link :to="item.url"
					><el-button type="primary">了解详情</el-button></router-link
				>
			</div>
		</div>
		<div class="bar_line">
			<div>解决方案</div>
			<div>Solution</div>
		</div>
		<Carousel :cardData="solution" dark noPadding></Carousel>
		<div class="bar_line">
			<div>面向行业</div>
			<div>Industry Oriented</div>
		</div>
		<Carousel :cardData="industry" dark noPadding></Carousel>
	</div>
</template>

<script>
import Carousel from '../components/Carousel.vue'
import { reactive, toRefs } from 'vue'
export default {
	components: {
		Carousel
	},
	setup() {
		const state = reactive({
			items: [
				{
					title: 'IOT物联网平台',
					desc: '更精准的管理，更懂你的设备',
					url: '/product/iot',
					img: require('../assets/bc/5.png')
				},
				{
					title: '边缘网关',
					desc: '是网关，也是计算与控制的核心',
					url: '/product/gateway',
					img: require('../assets/bc/6.png')
				},
				{
					title: '碳资产管理',
					desc: '谁在排放、排放源是什么、排放了多少？了如指掌',
					url: '/product/carbon',
					img: require('../assets/bc/7.png')
				},
				{
					title: '绿色金融与节能服务',
					desc: '一站式、交钥匙的节能服务',
					url: '/product/finance',
					img: require('../assets/bc/17.png')
				}
			],
			solution: [
				{
					text: '物联网基础数据服务中台',
					desc: '提供基础数据标准API，与业务系统完全解耦',
					url: '/solution/iot',
					img: require('../assets/bc/9.png')
				},
				{
					text: '故障预测与健康管理',
					desc: '发现早期故障特征、预测故障发展趋势',
					url: '/solution/manage',
					img: require('../assets/bc/10.png')
				},
				{
					text: '零碳工厂与零碳园区',
					desc: '以节能服务、碳资产管理赋能零碳工厂与零碳园区建设',
					url: '/solution/zerocarbon',
					img: require('../assets/bc/11.png')
				},
				{
					text: '区域绿色高质量发展与双碳平台',
					desc: '数据是基础、管理是手段、零碳是目标',
					url: '/solution/carbon',
					img: require('../assets/bc/12.png')
				}
			],
			industry: [
				{
					text: '钢铁业',
					desc: '助力钢铁企业“百万设备上网”',
					url: '/industry/iron',
					img: require('../assets/bc/13.png')
				},
				{
					text: '纺织业',
					desc: '纺织设备数据联网与节能控制优化',
					url: '/industry/spin',
					img: require('../assets/bc/14.png')
				},
				{
					text: '智慧园区',
					desc: '园区设备在线监控与智能运维',
					url: '/industry/park',
					img: require('../assets/bc/15.png')
				},
				{
					text: '连锁酒店',
					desc: '为连锁酒店提供基于宽带服务绑定的IOT SaaS增值服务',
					url: '/industry/hotel',
					img: require('../assets/bc/16.png')
				}
			]
		})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style scoped lang="scss">
.home {
	.home_top {
		height: 500px;
		background-image: url(../assets/bc/4.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		color: #fff;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.title {
			font-size: 60px;
		}
		.desc {
			font-size: 30px;
			margin-top: 20px;
		}
	}
	.item:nth-child(2n) {
		text-align: right;
	}
	.item:nth-child(2n + 1) {
		text-align: left;
	}
	.item {
		height: 920px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 10%;
		.title {
			font-size: 46px;
		}
		.desc {
			font-size: 30px;
		}
		div + div {
			margin-top: 20px;
		}
	}
	.bar_line {
		height: 226px;
		text-align: center;
		font-size: 30px;
		color: #fff;
		background: #1d1f1d;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
</style>
