<template>
	<div class="footer">
		<div class="flex top">
			<div class="info">
				<div>
					<img src="../assets/logo_white.png" alt="" />
				</div>
				<div><span>电话：</span>86+18016402640</div>
				<div><span>邮箱：</span>sales@leanloop.net</div>
				<div class="flex">
					<span>地址：</span>
					<div>
						上海市杨浦区纪念路8号3座311室<br />上海财经大学国家科技园区
					</div>
				</div>
			</div>
			<div>
				<img src="../assets/code.png" alt="" />
			</div>
		</div>
		<div>
			<p class="beian">
				版权所有 © 上海山楂互联网科技有限公司
				<a href="http://beian.miit.gov.cn/" target="_blank"
					><img src="../assets/beian.png" />
					<span>沪ICP备16010675号</span></a
				>
				<a
					href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002004740"
					target="_blank"
					><img src="../assets/beian.png" />
					<span>沪公网备16010675号</span></a
				>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Footer',
	props: {}
}
</script>

<style scoped lang="scss">
.footer {
	height: 264px;
	background: #000;
	color: #fff;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.top {
		justify-content: space-around;
	}
	.info {
		font-size: 14px;
		justify-content: space-around;
		line-height: 22px;
		img {
			width: 240px;
			height: 40px;
			margin-bottom: 15px;
		}
	}
	img {
		width: 140px;
		height: 140px;
	}
	.beian {
		font-size: 12px;
		text-align: center;
		position: absolute;
		bottom: 20px;
		text-align: center;
		width: 100%;
		a {
			color: #fff;
			img {
				width: 14px;
				height: 14px;
				vertical-align: middle;
				margin-right: 12px;
			}
			span {
				vertical-align: middle;
			}
		}
		a {
			margin-left: 40px;
		}
	}
}
</style>
