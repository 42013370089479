<template>
	<div>
		<div class="banner">
			<div class="title">能源管理系统</div>
			<div class="desc">
				对企业和园区边界内的水、电、燃气、热力进行分项计量，<br />提供集抄表、收费、账单、能耗统计、限额管理、故障告警、需量预警等于一体的能源管理服务。
			</div>
		</div>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">技术框架</div>
				<div class="en">Technical Framework</div>
			</div>
			<div class="center_img">
				<img src="../../assets/product/energy5.png" alt="" style="width:840px;"/>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">核心能力</div>
				<div class="en">Core Competences</div>
			</div>
		</div>
		<LeftRightTree :treeData="tree"></LeftRightTree>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">应用场景</div>
				<div class="en">Application Scenarios</div>
			</div>
		</div>
		<Carousel :cardData="cards"></Carousel>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Carousel from '../../components/Carousel.vue'
import LeftRightTree from '../../components/LeftRightTree.vue'
export default {
	components: {
		Carousel,
		LeftRightTree
	},
	setup() {
		const state = reactive({
			cards: [
				{
					text: '零碳工厂与零碳园区',
					desc: '以节能服务、碳资产管理赋能零碳工厂与零碳园区建设',
					url: '/solution/zerocarbon',
					img: require('../../assets/bc/11.png')
				},
				{
					text: '纺织业',
					desc: '纺织设备数据联网与节能控制优化',
					url: '/industry/spin',
					img: require('../../assets/bc/14.png')
				},
				{
					text: '智慧园区',
					desc: '园区设备在线监控与智能运维',
					url: '/industry/park',
					img: require('../../assets/bc/15.png')
				},
				{
					text: '连锁酒店',
					desc: '为连锁酒店提供基于宽带服务绑定的IOT SaaS增值服务',
					url: '/industry/hotel',
					img: require('../../assets/bc/16.png')
				}
			],
			tree: [
				{
					title: '多能监测',
					list: [
						'实时采集水、电、燃、冷、热等多能资源',
						'远程采集与手动抄表相结合',
						'能源流可视化监视'
					],
					img: require('../../assets/product/energy1.png')
				},
				{
					title: '能效指标分析',
					list: [
						'自定义能效指标及阈值，实现能效异常实时告警',
						'按横向功能区、纵向时间等多维度能效对标',
						'节能降耗、安全运行和精细化管理'
					],
					img: require('../../assets/product/energy2.png')
				},
				{
					title: '优化决策',
					list: [
						'基于用能负荷特性与设备状况、能源管理及消费状况、能耗指标综合分析、能源成本分析等给出报告建议'
					],
					img: require('../../assets/product/energy3.png')
				},
				{
					title: '虚拟电厂',
					list: [
						'调控光伏、储能、中央空调等设备资源',
						'作为负荷聚合商参与电力市场的辅助服务及需求侧响应'
					],
					img: require('../../assets/product/energy4.png')
				}
			]
		})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style scoped lang="scss">
.banner {
	background-image: url(../../assets/bc/19.png);
}
</style>
