<template>
	<div class="trial" :style="{ minHeight: pageHeight + 'px' }">
		<div class="cover"></div>
		<div class="form_box">
			<div v-show="!isSuccess">
				<h2>免费试用</h2>
				<el-form
					:model="form"
					label-width="80px"
					:rules="rules"
					ref="freeTrialFormRef"
				>
					<el-form-item label="试用产品" prop="productName">
						<el-select
							style="width: 100%"
							v-model="form.productName"
							:clearable="false"
							placeholder="请选择"
						>
							<el-option
								v-for="item in products"
								:label="item"
								:value="item"
								:key="item"
							/>
						</el-select>
					</el-form-item>
					<el-form-item label="姓名" prop="name">
						<el-input
							v-model="form.name"
							placeholder="请输入姓名"
							:maxlength="10"
						/>
					</el-form-item>
					<el-form-item label="联系方式" prop="phone">
						<el-input
							v-model="form.phone"
							placeholder="请输入11位手机号码"
						/>
					</el-form-item>
					<el-form-item label="验证码" prop="verifyCode">
						<el-row style="width:100%;">
							<el-col :span="15">
								<el-input
									v-model="form.verifyCode"
									placeholder="请输入验证码"
								></el-input
							></el-col>
							<el-col :span="1"></el-col>
							<el-col :span="8">
								<el-button
									type="primary"
									style="width:100%;"
									:disabled="sendCodeDiable"
									@click="sendCode"
									>{{
										sendCodeDiable
											? countDown + 's'
											: '发送验证码'
									}}</el-button
								></el-col
							>
						</el-row>
					</el-form-item>
					<el-form-item label="公司名称" prop="company">
						<el-input
							v-model="form.company"
							placeholder="请输入公司名称"
						/>
					</el-form-item>
					<el-form-item label="所属行业" prop="industry">
						<el-select
							style="width: 100%"
							v-model="form.industry"
							placeholder="请选择所属行业"
							filterable
							allow-create
							default-first-option
						>
							<el-option
								v-for="item in industrys"
								:label="item"
								:value="item"
								:key="item"
							/>
						</el-select>
					</el-form-item>
					<el-form-item label="联系邮箱" prop="email">
						<el-input
							v-model="form.email"
							placeholder="请输入联系邮箱"
						/>
					</el-form-item>
					<el-form-item label="说明" prop="remark">
						<el-input
							v-model="form.remark"
							type="textarea"
							placeholder="请输入"
						/>
					</el-form-item>
					<el-form-item>
						<el-button
							type="primary"
							@click="onSubmit(freeTrialFormRef)"
							style="width: 100%"
							>提交</el-button
						>
					</el-form-item>
				</el-form>
				<span class="comment">注：提交后，将自动提供免费安装包、跳转链接、试用账号和密码等信息</span>
			</div>
			<div v-show="isSuccess" class="success">
				<el-icon size="200px" color="#70b603"><CircleCheck /></el-icon>
				<h3>提交成功</h3>
				<div>
					<div v-if="form.productName == 'IOT物联网平台'">
						<!-- <div>
							下载链接：
							<span class="blue">
								<a
									href="https://www.baidu.com/345/345/47/dgg/345/345/47/dgg345/345/47/dgg345/345/47/dg"
									target="_blank"
									>https://www.baidu.com/345/345/47/dgg/345/345/47/dgg345/345/47/dgg345/345/47/dg</a
								></span
							>
						</div> -->
						<div>
							试用网址：
							<span class="blue">
								<a
									href="https://www.leanloop.net"
									target="_blank"
									>https://www.leanloop.net</a
								></span
							>
						</div>
						<div>试用账号：13800880088</div>
						<div>试用密码：asdf123456</div>
					</div>
					<div v-else>
						<div>
							试用网址：
							<span class="blue">
								<a
									href="https://cpn.leanloop.net"
									target="_blank"
									>https://cpn.leanloop.net</a
								></span
							>
						</div>
						<div>试用账号：13800880018</div>
						<div>试用密码：qwer123456</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'
export default {
	components: {},
	setup() {
		const state = reactive({
			isSuccess: false,
			pageHeight: computed(() => {
				return document.documentElement.clientHeight - 344
			}),
			freeTrialFormRef: null,
			countDown: 60,
			sendCodeDiable: false,
			form: {
				productName: 'IOT物联网平台',
				name: '',
				phone: '',
				verifyCode: '',
				company: '',
				industry: null,
				email: '',
				remark: ''
			},
			rules: {
				name: [
					{
						required: true,
						message: '请输入姓名',
						trigger: ['blur', 'change']
					}
				],
				phone: [
					{
						required: true,
						message: '请输入联系方式',
						trigger: ['blur', 'change']
					},
					{
						pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
						message: '请输入11位手机号码',
						trigger: ['blur', 'change']
					}
				],
				verifyCode: [
					{
						required: true,
						message: '请输入验证码',
						trigger: ['blur']
					}
				],
				company: [
					{
						required: true,
						message: '请输入公司名称',
						trigger: ['blur', 'change']
					}
				],
				email: [
					{
						type: 'email',
						message: '请输入正确的邮箱',
						trigger: ['blur', 'change']
					}
				]
			},
			products: ['IOT物联网平台', '碳资产管理系统'],
			industrys: [
				'农林牧渔业',
				'采矿业',
				'制造业',
				'电热燃水生产与供应',
				'建筑业',
				'批发零售',
				'交通运输仓储',
				'住宿餐饮',
				'互联网',
				'金融业',
				'房地产业',
				'商务服务',
				'科技服务',
				'水利环境与公共设施',
				'居民服务',
				'教育',
				'卫生与社会工作',
				'文体娱乐',
				'公共机构',
				'国际组织'
			]
		})
		const sendCode = () => {
			state.freeTrialFormRef.validateField('phone', (isValid) => {
				if (!isValid) {
					return false
				} else {
					getVerifyFun()
					settime()
				}
			})
		}
		const getVerifyFun = async () => {
			axios.get('https://www.leanloop.net/apiv4/auth/verify/obtain',{
				params:{
					id: state.form.phone,
					opt:1
				}
			}).then(res => {
				ElMessage({
					type: res.data.Code == 200 ? 'success' : 'error',
					message: res.data.Message
				})
			}).catch((err) => {
				ElMessage.error(err.response.data)
			})
		}
		const settime = () => {
			if (state.countDown == 0) {
				state.sendCodeDiable = false
				state.countDown = 60
			} else {
				state.sendCodeDiable = true
				state.countDown--
				setTimeout(() => {
					settime()
				}, 1000)
			}
		}
		const onSubmit = async (formEl) => {
			if (!formEl) return
			formEl.validate((valid) => {
				if (valid) {
					axios
						.post(
							'https://www.leanloop.net/apiv4/common/wform/1',
							state.form
						)
						.then((res) => {
							console.log(res.data)
							state.isSuccess = true
						})
						.catch((err) => {
							// ElMessage.error('提交失败，请稍后重试')
							ElMessage.error(err.response.data.Message)
							console.log(err.response.data)
						})
				} else {
					return false
				}
			})
		}
		return {
			...toRefs(state),
			onSubmit,
			sendCode
		}
	}
}
</script>

<style scoped lang="scss">
.trial {
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url(../assets/bc/30.png);
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
	position: relative;
	.cover{
		content: "";
		position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1b4570;
    opacity: .45;
    display: block;
		top:0;
		left: 0;
	}
	.form_box {
		z-index:10;
		margin: 60px 0;
		width: 510px;
		// min-height: 560px;
		min-height: 688px;
		box-sizing: border-box;
		padding: 60px;
		background-color: #fff;
		border: 1px solid #ddd;
		box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 15%);
		border-radius: 10px;
		h2 {
			margin-bottom: 40px;
			text-align: center;
		}
		.success {
			display: flex;
			flex-direction: column;
			align-items: center;
			color: #606266;
			line-height: 32px;
			font-size: 14px;
			.el-icon {
				display: block;
			}
			h3 {
				margin: 30px 0;
			}
			.blue {
				color: #409eff;
				display: inline-block;
				max-width: 300px;
				word-break: break-all;
				vertical-align: top;
				white-space: nowrap;
				overflow-x: hidden;
				text-overflow: ellipsis;
				a {
					color: #409eff;
				}
			}
		}
		.comment{
			font-size: 12px;
			color:#666;
		}
	}
}
</style>
