<template>
	<div>
		<div class="banner">
			<div class="title text-center">区域绿色高质量发展与双碳平台</div>
			<div class="desc text-center">
				围绕数据是基础、管理是手段、零碳是目标的思路，基于采好数据、管好数据、用好数据的理念，<br />以数据驱动决策，构建区域能碳一体管控平台。
			</div>
		</div>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">平台功能</div>
				<div class="en">Platform Functions</div>
			</div>
			<div class="func_title">
				<div>
					一屏感知，一网研判，一键直达
				</div>
			</div>
			<div class="func_block">
				<div class="item" v-for="(item, index) in funcs" :key="index">
					<div class="title">{{ item.title }}</div>
					<img :src="item.img" alt="" />
					<div></div>
					<div class="sec_title">{{ item.secTitle }}</div>
					<div class="list">
						<div v-for="(value, key) in item.list" :key="key">
							{{ value }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">平台优势</div>
				<div class="en">Platform Advantages</div>
			</div>
		</div>
		<LeftRightTree :treeData="tree"></LeftRightTree>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">相关产品</div>
				<div class="en">Related Products</div>
			</div>
		</div>
		<Carousel :cardData="cards"></Carousel>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Carousel from '../../components/Carousel.vue'
import LeftRightTree from '../../components/LeftRightTree.vue'
export default {
	components: {
		Carousel,
		LeftRightTree
	},
	setup() {
		const state = reactive({
			cards: [
				{
					text: '能源管理',
					desc: '分项计量与多能监测',
					url: '/product/energy',
					img: require('../../assets/bc/3.png')
				},
				{
					text: '碳资产管理',
					desc: '盘查摸底碳排放源与排放量',
					url: '/product/carbon',
					img: require('../../assets/bc/2.png')
				},
				{
					text: '绿色金融与节能服务',
					desc: '与用户共享节能投资收益',
					url: '/product/finance',
					img: require('../../assets/bc/17.png')
				}
			],
			tree: [
				{
					title: '顶层设计',
					list: [
						'参与城乡绿色高质量发展相关标准的制定',
						'从顶规视角参与区域双碳规划与碳脑平台建设'
					],
					img: require('../../assets/solution/carbon1.png')
				},
				{
					title: '资源共享',
					list: [
						'与国内领先的产业智库、双碳智库、投资机构建立战略合作关系，实现上下游资源共享'
					],
					img: require('../../assets/solution/carbon2.png')
				},
				{
					title: '助力招商',
					list: [
						'帮助区域政府引入绿色产业发展资源，助力多个县域政府绿色产业招商'
					],
					img: require('../../assets/solution/carbon3.png')
				}
			],
			funcs: [
				{
					title: '数据分析',
					secTitle: '摸清区域能碳现状',
					list: [
						'区域经济发展总览',
						'区域能耗低碳一张图（在线监测）',
						'能源与排放多维度分析',
						'节能减排展示（企业、项目）',
						'抵消及碳汇吸收'
					],
					img: require('../../assets/solution/func1.png')
				},
				{
					title: '过程管控',
					secTitle: '节能降碳场景应用',
					list: [
						'低碳/零碳企业、社区、绿色建筑',
						'科技园区智慧能源管理场景',
						'资源循环利用场景',
						'减排增汇潜力挖掘场景'
					],
					img: require('../../assets/solution/func2.png')
				},
				{
					title: '目标跟踪',
					secTitle: '辅助决策分析管理',
					list: [
						'双控考核目标分解与跟踪',
						'区域中和测算与路径跟踪',
						'产业碳评估',
						'低碳现状评估（企业画像）',
						'指标预测与预警'
					],
					img: require('../../assets/solution/func3.png')
				}
			]
		})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style scoped lang="scss">
.banner {
	background-image: url(../../assets/bc/1.png);
}
.func_title{
	display: flex;
	justify-content: center;
	margin-bottom:50px;
	div{
		width: 600px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    background-color: rgb(59 101 241 / 80%);
    border-radius: 10px;
    color: #fff;
    box-shadow: 0 0 8px 4px rgb(57 99 240 / 30%);
	}
}
.func_block {
	display: flex;
	justify-content: center;
	padding-bottom: 60px;
	.item {
		width: 360px;
		height: 486px;
		background: #ffffff;
		box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		.title {
			font-size: 20px;
			color: #0183f8;
			// line-height: 60px;
			// height: 60px;
			margin: 40px 0;
		}
		img {
			width: 166px;
		}
		.sec_title {
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
			// line-height: 32px;
			// height: 32px;
			margin: 30px 0 10px;
		}
		.list {
			color: #898989;
			font-size: 14px;
			text-align: center;
			line-height: 24px;
		}
	}
	.item + .item {
		margin-left: 60px;
	}
}
</style>
