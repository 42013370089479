<template>
	<div>
		<div class="banner">
			<div class="title text-center">绿色金融与节能服务</div>
			<div class="desc text-center">
				针对医院、数据中心、物流冷库及高能耗工业企业用户，领环以合同能源管理（EMC）模式，<br />与用户共享节能投资收益。
			</div>
		</div>
		<div class="bg_blue">
			<div class="common_block_title">
				<div class="cn">EMC合作模式</div>
				<div class="en">Cooperation Mode</div>
			</div>
			<div class="emc">
				<img src="../../assets/product/finance5.png" alt="" />
				<div class="list">
					<div
						class="item"
						v-for="(item, index) in emcs"
						:key="index"
					>
						<img :src="item.img" alt="" />
						<div class="text">{{ item.text }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">核心能力</div>
				<div class="en">Core Competences</div>
			</div>
		</div>
		<LeftRightTree :treeData="tree"></LeftRightTree>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">服务优势</div>
				<div class="en">Service Advantages</div>
			</div>
			<div class="service">
				<div>
					目前国内90%制冷机房年平均能效比在2.5-3.5区间，按照高效制冷机房能效比5.0计算，<br />领环EMC服务可提供高达40%的节能提升空间。
				</div>

				<img src="../../assets/product/finance9.png" alt="" />
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">应用案例</div>
				<div class="en">Application Case</div>
			</div>
		</div>
		<Carousel :cardData="cards"></Carousel>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Carousel from '../../components/Carousel.vue'
import LeftRightTree from '../../components/LeftRightTree.vue'
export default {
	components: {
		Carousel,
		LeftRightTree
	},
	setup() {
		const state = reactive({
			emcs: [
				{
					text: '提供能耗问题识别、方案设计、绿色融资、施工改造、运行维护等服务，显著降低能耗及碳排放。',
					img: require('../../assets/product/finance6.png')
				},
				{
					text: '对建筑电气、BA系统、暖通空调系统、给排水系统等整体优化改造，动态投资回报期在8年以内。',
					img: require('../../assets/product/finance7.png')
				},
				{
					text: '支持固定单价或总价的能源运维模式，合同期结束后，设备资产所有权全部归用户所有。',
					img: require('../../assets/product/finance8.png')
				}
			],
			cards: [
				{
					text: '零碳工厂与零碳园区',
					desc: '以节能服务、碳资产管理赋能零碳工厂与零碳园区建设',
					url: '/solution/zerocarbon',
					img: require('../../assets/bc/11.png')
				},
				{
					text: '智慧园区',
					desc: '园区设备在线监控与智能运维',
					url: '/industry/park',
					img: require('../../assets/bc/15.png')
				},
				{
					text: '区域绿色高质量发展与双碳平台',
					desc: '数据是基础、管理是手段、零碳是目标',
					url: '/solution/carbon',
					img: require('../../assets/bc/12.png')
				}
			],
			tree: [
				{
					title: 'AI算法实现深度节能',
					list: [
						'基于“数据驱动+机理框架”的高精度负荷仿真预测',
						'主动寻优，逼近综合能源系统效率极限',
						'在常规自控系统基础上额外提高10%~15%的节能率'
					],
					img: require('../../assets/product/finance1.png')
				},
				{
					title: '分项计量与能效管控一体化',
					list: [
						'能源分类、分项计量',
						'变水温、变流量，质调节、量调节',
						'为能效管理、算法、策略提供数据支持'
					],
					img: require('../../assets/product/finance2.png')
				},
				{
					title: '负荷动态预测和随动',
					list: [
						'依据环境因素、负荷时间特性、能源分段计费差异、末端使用情况，进行负荷动态预测与调度',
						'实时调控，按需供能'
					],
					img: require('../../assets/product/finance3.png')
				},
				{
					title: '风水联动优化控制',
					list: [
						'空调水系统和末端风系统联动控制，闭环高效运行',
						'远程监控系统运行状态，反馈调节空调主机和水泵运行参数'
					],
					img: require('../../assets/product/finance4.png')
				}
			]
		})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style scoped lang="scss">
.banner {
	background-image: url(../../assets/bc/22.png);
}
.emc {
	display: flex;
	flex-direction: column;
	align-items: center;
	& > img {
		width: 60%;
		max-width: 1200px;
	}
	.list {
		display: flex;
		justify-content: center;
		margin-top: 60px;
		.item {
			width: 20%;
			padding: 40px;
			box-sizing: border-box;
			text-align: center;
			.text {
				text-align: left;
				font-size: 14px;
				color: #333333;
				line-height: 28px;
			}
			img {
				width: 80%;
				max-width: 220px;
			}
		}
	}
}
.service {
	text-align: center;
	color: #666666;
	padding-bottom: 60px;
	line-height: 30px;
	font-size: 16px;
	img {
		margin-top: 40px;
		width: 60%;
	}
}
</style>
