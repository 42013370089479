<template>
	<div>
		<div class="banner">
			<div class="title text-center">故障预测与健康管理</div>
			<div class="desc text-center">
				发现早期故障特征、预测故障发展趋势、延长设备使用寿命、减少设备停运损失，<br />实现设备运维闭环管理。
			</div>
		</div>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">方案架构</div>
				<div class="en">Solution Schema</div>
			</div>
			<div class="center_img">
				<img src="../../assets/solution/manage4.png" alt="" style="width:980px;"/>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">方案优势</div>
				<div class="en">Solution Advantages</div>
			</div>
		</div>
		<LeftRightTree :treeData="tree"></LeftRightTree>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">相关产品</div>
				<div class="en">Related Products</div>
			</div>
		</div>
		<Carousel :cardData="cards"></Carousel>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Carousel from '../../components/Carousel.vue'
import LeftRightTree from '../../components/LeftRightTree.vue'
export default {
	components: {
		Carousel,
		LeftRightTree
	},
	setup() {
		const state = reactive({
			cards: [
				{
					text: 'IOT物联网平台',
					desc: '工业级物联网数据集成与数据资源管理',
					url: '/product/iot',
					img: require('../../assets/bc/24.png')
				},
				{
					text: '边缘网关',
					desc: '设备侧的边缘计算PaaS平台',
					url: '/product/gateway',
					img: require('../../assets/bc/25.png')
				},
				{
					text: '钢铁业',
					desc: '助力钢铁企业“百万设备上网”',
					url: '/industry/iron',
					img: require('../../assets/bc/13.png')
				}
			],
			tree: [
				{
					title: '基于工业IOT的AI算法诊断',
					list: [
						'机械机理与物联网驱动的AI诊断模型',
						'常见设备故障诊断准确率≥95%'
					],
					img: require('../../assets/solution/manage1.png')
				},
				{
					title: '结果导向型设备管理',
					list: [
						'提前预判早期故障',
						'预防性维护、检修与保养',
						'减少过度维护、降低运营成本'
					],
					img: require('../../assets/solution/manage2.png')
				},
				{
					title: '快速部署与响应',
					list: [
						'方案设计与报价，5天',
						'设备部署，5天',
						'标准软件交付，5天',
						'专家现场支持'
					],
					img: require('../../assets/solution/manage3.png')
				}
			]
		})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style scoped lang="scss">
.banner {
	background-image: url(../../assets/bc/26.png);
}
</style>
