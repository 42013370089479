<template>
	<router-view />
</template>

<script>
import { computed, toRefs, reactive, watch } from 'vue'
import { useRoute } from 'vue-router'
export default {
	name: 'App',
	components: {},
	setup() {
		const route = useRoute()
		const state = reactive({
			onRoutes: computed(() => {
				return route.path
			})
		})
		watch(
			() => state.onRoutes,
			() => {
				// 回到页面顶部
				document.body.scrollTop = document.documentElement.scrollTop = 0
				// if (val === '/home') {
				// 	document.title = route.meta.title
				// } else {
				// 	document.title = `${route.meta.title}_领环leanloop-上海山楂互联网科技有限公司`
				// }
			}
		)
		return { ...toRefs(state) }
	}
}
</script>

<style lang="scss">
* {
	padding: 0;
	margin: 0;
}

body {
	font-family: 'PingFang SC', 'Helvetica Neue', Helvetica, 'microsoft yahei',
		arial, STHeiTi, sans-serif;
}

a,
li {
	text-decoration: none;
	list-style: none;
}
#app {
	/* font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50; */
}

/*滚动条*/
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background-color: rgb(241, 241, 241);
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: rgb(241, 241, 241);
}

::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background-color: rgba(193, 193, 193, 0.4);
}
.flex {
	display: flex;
}
.mr-20 {
	margin-right: 20px;
}
.ml-20 {
	margin-left: 20px;
}
.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.bg_blue {
	background-color: #f1f8ff;
}
.bg_gray {
	background-color: #f5f5f5;
}
.bg_white {
	background-color: #ffffff;
}
/* banner */
.banner {
	height: 400px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.title {
		font-size: 28px;
	}
	.title_sec {
		font-size: 20px;
	}
	.desc {
		font-size: 16px;
		line-height: 30px;
	}
	div + div {
		margin-top: 20px;
	}
	& > div {
		width: 50%;
	}
	// // 立即试用 按钮
	// .banner_btn {
	// 	width: 136px;
	// 	height: 36px;
	// 	line-height: 36px;
	// 	color: #0183f8;
	// 	font-size: 14px;
	// 	text-align: center;
	// 	border-radius: 20px;
	// 	width: 136px;
	// 	height: 36px;
	// 	background: #ffffff;
	// 	border-radius: 20px;
	// }
}
// 块级title
.common_block_title {
	text-align: center;
	padding: 60px 0;
	.cn {
		font-size: 30px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #333333;
		line-height: 30px;
	}
	.en {
		font-size: 30px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #dddddd;
		line-height: 30px;
		margin-top: 17px;
	}
}
// 居中图片
.center_img {
	text-align: center;
	padding-bottom: 60px;
	img {
		width: 50%;
	}
}
// 精选案例
.common_case {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 60px;
	img,
	.text {
		width: 25%;
	}
	img{
		box-shadow: 0 0 8px #666;
	}
	.text {
		color: #333;
		font-size: 14px;
		line-height: 30px;
		margin-left: 80px;
		div + div {
			margin-top: 20px;
		}
		.bold {
			font-weight: 600;
		}
		ul {
			padding-left: 16px;
			li {
				list-style: disc;
			}
		}
	}
}
.titles{
	justify-content: center;
	display: flex;
	margin-bottom: 30px;
	.box{
		display: flex;
    justify-content: space-between;
		width: 50%;
		font-size: 16px;
		color:#666;
		text-shadow: -8px -6px 2px #ddd;
	}
}
</style>
