<template>
	<div class="about">
		<div class="banner">
			<div class="title text-center">关于我们</div>
			<div class="desc">
				上海山楂互联网科技有限公司成立于2015年，拥有“领环®”专用商标，是高和资本战略投资的一家物联网科技超融合平台。团队成员深耕物联网、大数据领域数十年，从最初的工业控制SCADA、公交调度与客流OD分析，到MES与场内物流系统。经过多轮产品迭代和升级，领环科技推出IOT物联网平台V4.0、碳资产管理系统两大核心产品，并与产业链上下游联手打造多个零碳应用场景。我们的产品以“标准、物联、共享”为宗旨，助力生产工厂、产业园区、城市建筑、大型酒店等客户实现数字化转型。
				<br />
				在“碳达峰、碳中和”国家战略框架下，能源系统转型升级势在必行，从自动化、数字化，最终智能化，将逐步达到节能、减排、提效的绿色发展目标。领环作为物联网科技超融合平台，愿与广大客户和合作伙伴并肩前行，携手共建安全、绿色、智慧的物联网生态，助力实现双碳目标。
			</div>
		</div>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">专利荣誉</div>
				<div class="en">Patent Honor</div>
			</div>
			<div class="honor">
				<ul>
					<li>上海市高新技术企业，累计24项软件著作权</li>
					<li>索迪斯亚太区设施管理数字化战略转型支撑平台</li>
					<li>阿里云首批IOT业务战略合作伙伴</li>
					<li>中关村标准化协会智慧园区软件建设标准团标主编单位</li>
					<li>
						中国生物多样性保护与绿色发展基金会-绿色发展与双循环工作委员会常委单位
					</li>
					<li>2021年毕马威中国领先地产科技50佳企业</li>
					<li>获2019年IFMA亚太杰出设施管理科技奖</li>
					<li>评为2019年微软物联网优秀孵化项目</li>
					<li>入选2018年上海市人工智能创新发展专项支持项目</li>
					<li>累计服务六十余家世界五百强企业</li>
				</ul>
				<div>
					<img src="../assets/about/1.png" alt="" />
					<img src="../assets/about/2.png" alt="" />
					<img src="../assets/about/3.png" alt="" />
				</div>
			</div>
			<div class="zs">
				<img src="../assets/about/4.png" alt="" />
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">合作伙伴</div>
				<div class="en">Cooperative Partner</div>
			</div>
			<div class="partner">
				<div class="item">
					<img src="../assets/partner/1.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/2.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/3.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/4.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/5.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/6.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/7.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/8.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/9.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/10.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/11.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/12.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/13.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/14.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/15.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/16.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/17.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/18.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/19.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/20.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/21.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/22.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/23.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/24.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/25.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/26.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/27.png" alt="" />
				</div>
				<div class="item">
					<img src="../assets/partner/28.png" alt="" />
				</div>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">联系我们</div>
				<div class="en">Contact Us</div>
			</div>
		</div>
		<div class="loc" id="loc">
		</div>
	</div>
</template>
<script>
import { reactive, toRefs, onMounted} from 'vue'
import AMap from 'AMap'
export default {
	components: {},
	setup() {
		const state = reactive({
		})
		onMounted(() => {
			let map = new AMap.Map('loc', {
				zoom: 18, //级别
				center: [121.49266863061902, 31.297762359189626],
				resizeEnable: true
			})
			let marker = new AMap.Marker({
				position: new AMap.LngLat(121.49266863061902, 31.297762359189626),
				title: '领环'
			})
			map.add(marker)
			var infoWindow = new AMap.InfoWindow({
        anchor: 'top-left',
        content: '电话：86+18016402640<br/>sales@leanloop.net<br>地址：上海市杨浦区纪念路8号3座311室',
			})
			infoWindow.open(map,[121.49266863061902, 31.297762359189626])
			marker.on('click', function() {
				infoWindow.open(map,[121.49266863061902, 31.297762359189626])
			})
		})
		return {
			...toRefs(state),
		}
	}
}
</script>

<style scoped lang="scss">
.about {
}
.banner {
	background-image: url(../assets/bc/29.png);
}
.honor {
	display: flex;
	justify-content: center;
	align-items: center;
	ul {
		width: 30%;
		padding-left: 18px;
		box-sizing: border-box;
		li {
			list-style: disc;
			color: #666666;
			font-size: 14px;
			line-height: 28px;
		}
	}
	div {
		width: 40%;
		img {
			width: calc((100% - 120px) / 3);
			margin-left: 40px;
		}
	}
}
.zs {
	text-align: center;
	padding: 60px 0;
	img {
		width: 70%;
	}
}
.partner {
	display: flex;
	width: 1058px;
	height: 1258px;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	align-content: space-between;
	margin-left: calc(50% - 529px);
	.item {
		border: 1px solid #ddd;
		width: 242px;
		height: 162px;
		box-sizing: border-box;
		padding: 0 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 200px;
		}
	}
}
.loc {
	width: 100%;
	height: 400px;
	text-align: center;
}
</style>
