<template>
	<div class="public">
		<v-header />
		<div class="content-box">
			<div class="content">
				<router-view />
			</div>
		</div>
		<SideTool></SideTool>
		<v-footer />
	</div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import vHeader from '../components/Header.vue'
import vFooter from '../components/Footer.vue'
import SideTool from '../components/SideTool.vue'

export default {
	components: {
		vHeader,
		vFooter,
		SideTool
	},
	setup() {
		const state = reactive({})
		onMounted(() => {})

		return {
			...toRefs(state)
		}
	}
}
</script>
<style lang="scss" scoped>
.public {
	padding-top: 80px;
}
</style>
