<template>
	<div>
		<div class="banner">
			<div class="title text-center">边缘网关</div>
			<div class="desc text-center">
				部署于设备现场，集数据采集、通讯、策略引擎、AI推理于一体的边缘计算PaaS平台
			</div>
		</div>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">软件架构</div>
				<div class="en">Software Schema</div>
			</div>
			<div class="center_img">
				<img src="../../assets/product/gateway4.png" alt=""  style="width:800px;"/>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">核心能力</div>
				<div class="en">Core Competences</div>
			</div>
		</div>
		<LeftRightTree :treeData="tree"></LeftRightTree>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">硬件规格</div>
				<div class="en">Specifications</div>
			</div>
			<div class="tabsBox">
				<div  class="tabs_content">
					<el-tabs v-model="activeName">
						<el-tab-pane label="一型边缘网关" name="tab1">
						</el-tab-pane>
						<el-tab-pane label="二型信创网关" name="tab2">
						</el-tab-pane>
					</el-tabs>

				</div>

			</div>
			<div class="specification" v-show="activeName=='tab1'">
				<div class="left">
					<div class="top">
						<img :src="curG" alt="" />
					</div>
					<div class="bottom">
						<img :src="item" alt="" v-for="(item,index) in gs" :key="index" @click="curG = item">
						<!-- <img src="../../assets/product/gateway5.png" alt="" />
						<img src="../../assets/product/gateway6.png" alt="" />
						<img src="../../assets/product/gateway7.png" alt="" />
						<img src="../../assets/product/gateway9.png" alt="" /> -->
					</div>
				</div>
				<div class="right">
					<el-descriptions title="硬件规格" size="small" border :column="1">
						<el-descriptions-item label="型号">LL-GATE100</el-descriptions-item>
						<el-descriptions-item label="处理器">瑞芯微RK3399处理器，2核ARM Cortex-A72 + 4核Cortex-A53，主频最高1.8GHz</el-descriptions-item>
						<el-descriptions-item label="内存">2GB/4GB</el-descriptions-item>
						<el-descriptions-item label="存储">8GB/16GB eMMC，TF Slot</el-descriptions-item>
						<el-descriptions-item label="AI性能">AI算力卡采用RK1808处理器，单卡3TOPS算力，可选配1-4张算力卡</el-descriptions-item>
						<el-descriptions-item label="串口">2个RS232R/S485自适应</el-descriptions-item>
						<el-descriptions-item label="USB">2个USB 3.0（Type A），1个Micro USB 2.0（OTG)</el-descriptions-item>
						<el-descriptions-item label="以太网">3个RJ45（10/100Mbps）</el-descriptions-item>
						<el-descriptions-item label="WiFi">可选配</el-descriptions-item>
						<el-descriptions-item label="4G">可选配</el-descriptions-item>
						<el-descriptions-item label="扩展接口">1个mini PCIe（4G/WIFI模块等）</el-descriptions-item>
						<el-descriptions-item label="显示接口">1个HDMI</el-descriptions-item>
						<el-descriptions-item label="电源接口">DC 12~24V</el-descriptions-item>
						<el-descriptions-item label="看门狗">硬件看门狗</el-descriptions-item>
						<el-descriptions-item label="操作系统">Ubuntu 18.04</el-descriptions-item>
					</el-descriptions>
					<!-- <img src="../../assets/product/gateway8.png" alt="" /> -->
				</div>
			</div>
			<div class="specification" v-show="activeName=='tab2'">
				<div class="left">
					<div class="top">
						<img src="../../assets/product/gateway10.png" alt="" />
					</div>
					<div class="bottom">
						<!-- <img :src="item" alt="" v-for="(item,index) in gs" :key="index" @click="curG = item"> -->
						<!-- <img src="../../assets/product/gateway10.png" alt="" /> -->
					</div>
				</div>
				<div class="right">
					<el-descriptions title="硬件规格" size="small" border :column="1">
						<el-descriptions-item label="型号">LL-GATE2000</el-descriptions-item>
						<el-descriptions-item label="处理器">支持1颗HYGON 3000系列处理器</el-descriptions-item>
						<el-descriptions-item label="内存">
							4根DDR4内存插槽，可扩展至128G内存<br />
							支持DDR4 2666 RECC 内存（工作频率依CPU和内存配置不同而不同）<br />
							支持RDIMM\UDIMM内存
						</el-descriptions-item>
						<el-descriptions-item label="PCIe扩展">最大可扩展4个PCIe插槽<br />
							1个PCI-E × 16 slot<br />
							1个PCI-E × 8 slot<br />
							1个PCI-E × 4 in slot
						</el-descriptions-item>
						<el-descriptions-item label="硬盘方案">最高支持1 LFF+1 SFF SATA 硬盘<br />
							1个M.2插槽</el-descriptions-item>
						<el-descriptions-item label="其他端口">4个USB 3.0接口和1组音频接口位于机箱前部<br />
							4个USB 3.0接口、1个千兆网口、1组音频接口位于机箱后部</el-descriptions-item>
						<el-descriptions-item label="电源">350W静音电源，200-240V，50HZ</el-descriptions-item>
						<el-descriptions-item label="显卡">可选 GT710\P400\P1000 型号</el-descriptions-item>
						<el-descriptions-item label="支持操作系统">Red Hat Enterprise Linux<br />
						Windows Server 2019, Windows 10</el-descriptions-item>
						<el-descriptions-item label="机箱">塔式机箱，330mm（长）*320mm（高）*90mm（宽）</el-descriptions-item>
						<el-descriptions-item label="工作温度">工作时：5~40℃<br />
						存储：-40~55℃</el-descriptions-item>
					</el-descriptions>
					<!-- <img src="../../assets/product/gateway8.png" alt="" /> -->
				</div>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">应用案例</div>
				<div class="en">Application Case</div>
			</div>
		</div>
		<Carousel :cardData="cards"></Carousel>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Carousel from '../../components/Carousel.vue'
import LeftRightTree from '../../components/LeftRightTree.vue'
import g5 from '../../assets/product/gateway5.png'
import g6 from '../../assets/product/gateway6.png'
import g7 from '../../assets/product/gateway7.png'
import g9 from '../../assets/product/gateway9.png'
export default {
	components: {
		Carousel,
		LeftRightTree
	},
	setup() {
		const state = reactive({
			activeName: 'tab1',
			curG: g5,
			gs: [
				g5,g6,g7,g9
			],
			cards: [
				{
					text: '物联网基础数据服务中台',
					desc: '提供基础数据标准API，与业务系统完全解耦',
					url: '/solution/iot',
					img: require('../../assets/bc/3.png')
				},
				{
					text: '钢铁业',
					desc: '助力钢铁企业“百万设备上网”',
					url: '/industry/iron',
					img: require('../../assets/bc/13.png')
				},
				{
					text: '纺织业',
					desc: '纺织设备数据联网与节能控制优化',
					url: '/industry/spin',
					img: require('../../assets/bc/14.png')
				},
				{
					text: '连锁酒店',
					desc: '为连锁酒店提供基于宽带服务绑定的IOT SaaS增值服务',
					url: '/industry/hotel',
					img: require('../../assets/bc/16.png')
				},
				{
					text: '故障预测与健康管理',
					desc: '发现早期故障特征、预测故障发展趋势',
					url: '/solution/manage',
					img: require('../../assets/bc/10.png')
				}
			],
			tree: [
				{
					title: '专注于数据采集与预处理',
					list: [
						'支持7大类南向物联网通信协议',
						'支持3大类北向物联网传输协议',
						'采集入库频率高达1,000,000次/秒*',
						'支持策略引擎',
						'内置Web管理界面',
						'具有完善的异常检测和防护机制'
					],
					img: require('../../assets/product/gateway1.png')
				},
				{
					title: '开放性软件架构',
					list: [
						'随意搭配第三方组态软件、控制器、扩充IO模块',
						'分层和模块化设计，支持对南向、北向协议的二次扩展定制',
						'支持ARM架构、X86架构',
						'支持Windows / Linux 各发行版系统',
						'容器化部署，不与特定硬件绑定'
					],
					img: require('../../assets/product/gateway2.png')
				},
				{
					title: '完备的硬件支持能力',
					list: [
						'双 USB3.0 Type-C 接口',
						'集成 MIPI/eDP 接口',
						'集成 HDMI2.0 接口',
						'H.265 / H.264 / VP9 4K@60fps 高清视频解码和显示',
						'内置 PCI-e 接口'
					],
					img: require('../../assets/product/gateway3.png')
				}
			]
		})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style scoped lang="scss">
.banner {
	background-image: url(../../assets/bc/20.png);
}
.tabsBox{
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
	.tabs_content{
		width:calc(320px + 40%);
	}
}
.specification {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 60px;
	.left {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 300px;
		margin-right: 20px;
		.top {
			width: 100%;
			text-align: center;
			margin-bottom: 20px;
			img {
				// width: 80%;
				width:230px;
				height:230px;
				border: 1px solid #ddd;
			}
		}
		.bottom {
			display: flex;
			justify-content: space-between;
			width: 100%;
			img {
				// width:20%;
				width:60px;
				height:60px;
				border: 1px solid #ddd;
				cursor: pointer;
			}
		}
	}
	.right {
		width: 40%;
		img {
			width: 100%;
		}
	}
}
</style>
