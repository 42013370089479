<template>
	<div>
		<div class="banner">
			<div class="title text-center">连锁酒店</div>
			<div class="desc text-center">
				为连锁酒店提供基于宽带服务绑定的IOT SaaS增值服务
			</div>
		</div>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">解决方案</div>
				<div class="en">Solutions</div>
			</div>
			<div class="center_img">
				<img src="../../assets/industry/hotel1.png" alt=""  style="width:900px;"/>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">解决方案</div>
				<div class="en">Solutions</div>
			</div>
		</div>
		<LeftRightTree :treeData="tree"></LeftRightTree>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">精选案例</div>
				<div class="en">Selected Cases</div>
			</div>
			<div class="common_case">
				<img src="../../assets/industry/hotel2.png" alt="" />
				<div class="text">
					<div class="bold">
						中国联通与中软国际、领环三方强强联手，为超过2000家品牌连锁酒店在不增加成本的前提下，提供酒店智能化升级服务，包括视讯、AI虚拟人、IOT网关、百兆带宽。
						其中，IOT网关提供：
					</div>
					<div>
						<ul>
							<li>
								IPTV智能网联终端与客控系统通过网关实现数据对接和指令转发；
							</li>
							<li>
								对接酒店环境内所有弱电智能化设备，包括明厨亮灶、视讯服务、智能客控、PMS平台、能耗计量，实现远程监控联网；
							</li>
							<li>根据业务需求灵活增加应用场景。</li>
						</ul>
					</div>
					<div class="bold">
						在基础套餐之上，该方案还可提供闲置客房资源置换IT升级服务的商务合作模式。
					</div>
				</div>
			</div>
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">相关产品</div>
				<div class="en">Related Products</div>
			</div>
		</div>
		<Carousel :cardData="cards"></Carousel>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Carousel from '../../components/Carousel.vue'
import LeftRightTree from '../../components/LeftRightTree.vue'
export default {
	components: {
		Carousel,
		LeftRightTree
	},
	setup() {
		const state = reactive({
			cards: [
				{
					text: 'IOT物联网平台',
					desc: '工业级物联网数据集成与数据资源管理',
					url: '/product/iot',
					img: require('../../assets/bc/24.png')
				},
				{
					text: '能源管理',
					desc: '分项计量与多能监测',
					url: '/product/energy',
					img: require('../../assets/bc/3.png')
				},
				{
					text: '边缘网关',
					desc: '设备侧的边缘计算PaaS平台',
					url: '/product/gateway',
					img: require('../../assets/bc/25.png')
				}
			],
			tree: [
				{
					title: '全面感控',
					list: [
						'覆盖电视盒、网关、客控、楼宇自控、消防、安防、能源、梯控、停车、照明、门禁等众多系统，实时获取点位状态、报警、故障等信息'
					],
					img: require('../../assets/industry/hotel3.png')
				},
				{
					title: '数据集成',
					list: [
						'提供数据接入、数据存储、数据治理、数据共享、数据安全、云边协同等全面物联网数据中台服务'
					],
					img: require('../../assets/industry/hotel4.png')
				},
				{
					title: '数据互联',
					list: [
						'智能化集成系统，打破数据孤岛、数据汇集处理、联动扩展性强，实现跨系统联动，实现数据治理、数据存储与标准化接口服务'
					],
					img: require('../../assets/industry/hotel5.png')
				},
				{
					title: '内置丰富物联协议',
					list: [
						'内置协议支持 RS485、 Modbus、DL/T645、CJ/T188、OPC UA、MQTT、CoAP 等协议；通过外置 OPC Server 支持 BACnet、KNX、CAN 等协议'
					],
					img: require('../../assets/industry/hotel6.png')
				}
			]
		})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style scoped lang="scss">
.banner {
	background-image: url(../../assets/bc/28.png);
}
</style>
