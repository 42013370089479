<template>
	<div class="left_right_tree">
		<div class="tree_item" v-for="(item, index) in treeData" :key="index">
			<div v-show="index % 2 === 0" class="img_box">
				<img :src="item.img" alt="" />
			</div>
			<div class="text">
				<div class="title">{{ item.title }}</div>
				<ul>
					<li v-for="(value, key) in item.list" :key="key">
						{{ value }}
					</li>
				</ul>
			</div>
			<div v-show="index % 2 === 1" class="img_box">
				<img :src="item.img" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		treeData: {
			type: Array,
			default: () => []
		}
	}
}
</script>

<style scoped lang="scss">
// 左右布局树
.left_right_tree {
	padding-bottom: 50px;
	.tree_item {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 260px;
		margin-bottom: 30px;
		.img_box {
			width: 20%;
			// margin-right: 5%;
			img {
				width: 100%;
			}
		}
		.text {
			width: 25%;
			&:before {
				content: '';
				display: block;
				width: 32px;
				height: 6px;
				background: #0183f8;
			}
			.title {
				font-size: 18px;
				line-height: 40px;
				color: #333333;
			}
			ul {
				font-size: 14px;
				line-height: 30px;
				color: #666;
				padding-left: 16px;
				li {
					list-style: disc;
				}
			}
		}
	}
	.tree_item:nth-child(2n + 1) {
		.img_box {
			margin-right: 10%;
		}
	}
	.tree_item:nth-child(2n) {
		.img_box {
			margin-left: 10%;
		}
	}
}
</style>
