<template>
	<div>
		<div class="banner">
			<div class="title text-center">智慧园区</div>
			<div class="desc text-center">
				基于IOT物联网平台与能源管理系统，搭建园区云设施监控平台，实现园区设备在线监控与智能运维。
			</div>
		</div>
		<div class="bg_white">
			<div class="items">
				<div class="item" v-for="(item, index) in items" :key="index">
					<div class="t1">{{ item.txt1 }}</div>
					<div class="t2">{{ item.txt2 }}</div>
				</div>
			</div>
		</div>
		<div class="bg_gray">
			<div class="common_block_title">
				<div class="cn">精选案例</div>
				<div class="en">Selected Cases</div>
			</div>
		<el-carousel :interval="6000" height="490px">
			<el-carousel-item >
				<div class="common_case">
					<img src="../../assets/industry/park1.png" alt="" />
					<div class="text">
						<div class="bold">LEED金奖 北京融中心</div>
						<div>
							融中心是集写字楼、高档商业设施于一体的多功能大型现代化商贸社区。本案例采用了如下绿色节能手段：<br />
							1）智能化设备均接入楼宇智能控制系统，实现在线远程监控；<br />
							2）所有仪表分项计量，对用能进行精细化管理；<br />
							3）采用电制冷中央空调系统和一次泵变频变流量设计，灵活应对用能端的负荷变化；<br />
							4）热源接市政热力，以增设气候补偿或楼宇自控管理方式进行调节。<br />
						</div>
						<div>
							改造后，大幅提升了单位面积能效水平，达到北京地区先进水平。
						</div>
					</div>
				</div>
			</el-carousel-item>
			<el-carousel-item >
				<div class="common_case">
					<img src="../../assets/industry/park2.png" alt="" />
					<div class="text">
						<div class="bold">长租公寓新地标 之寓未来</div>
						<div>
							之寓是湖北省科技投资集团旗下的长租公寓品牌，致力于集中式长租公寓的定制开发和品牌化运营。
						</div>
						<div>之寓未来总建筑面积55万㎡，是集公寓、商业、酒店、幼儿园、众创空间为一体的大型综合社区。</div>
						<div>该项目智慧化软件系统方案包括公寓、招商、物业管理、固定资产管理、云设施监控、智慧安防与应急调度等6大应用系统，以及数据、业务2大中台。领环深度参与从项目的早期规划、设计到实施、运维。</div>	
					</div>
				</div>
			</el-carousel-item>
			<el-carousel-item >
				<div class="common_case">
					<img src="../../assets/industry/park3.png" alt="" />
					<div class="text">
						<div class="bold">青岛腾讯双创小镇</div>
						<div>
							小镇总建筑面积120万㎡，规划为“产业园区-智慧社区-商业街区”三区共生的生态系统，覆盖产业园区、总部金融办公、LOFT、智慧社区、人才公寓、特色商业等多功能业态。
						</div>
						<div>
							作为该项目智慧化软件系统设计与承建商，领环与小镇的智慧化系统总集成商青岛联通一起，将“互联网+”应用于产品创新生态发展。
						</div>
					</div>
				</div>
			</el-carousel-item>
		</el-carousel>
			<!-- <div class="common_case">
				<img src="../../assets/industry/park1.png" alt="" />
				<div class="text">
					<div class="bold">LEED金奖 北京融中心</div>
					<div>
						融中心是集写字楼、高档商业设施于一体的多功能大型现代化商贸社区。本案例采用了如下绿色节能手段：<br />
						1）智能化设备均接入楼宇智能控制系统，实现在线远程监控；<br />
						2）所有仪表分项计量，对用能进行精细化管理；<br />
						3）采用电制冷中央空调系统和一次泵变频变流量设计，灵活应对用能端的负荷变化；<br />
						4）热源接市政热力，以增设气候补偿或楼宇自控管理方式进行调节。<br />
					</div>
					<div>
						改造后，大幅提升了单位面积能效水平，达到北京地区先进水平。
					</div>
				</div>
			</div> -->
		</div>
		<div class="bg_white">
			<div class="common_block_title">
				<div class="cn">相关产品</div>
				<div class="en">Related Products</div>
			</div>
		</div>
		<Carousel :cardData="cards"></Carousel>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Carousel from '../../components/Carousel.vue'
export default {
	components: {
		Carousel
	},
	setup() {
		const state = reactive({
			items: [
				{
					txt1: '300万 ㎡',
					txt2: '累计项目面积'
				},
				{
					txt1: '30+ 类',
					txt2: '对接弱电系统'
				},
				{
					txt1: '20 城',
					txt2: '项目遍及城市'
				}
			],
			cards: [
				{
					text: 'IOT物联网平台',
					desc: '工业级物联网数据集成与数据资源管理',
					url: '/product/iot',
					img: require('../../assets/bc/24.png')
				},
				{
					text: '能源管理',
					desc: '分项计量与多能监测',
					url: '/product/energy',
					img: require('../../assets/bc/3.png')
				},
				{
					text: '绿色金融与节能服务',
					desc: '与用户共享节能投资收益',
					url: '/product/finance',
					img: require('../../assets/bc/17.png')
				}
			]
		})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style scoped lang="scss">
.banner {
	background-image: url(../../assets/bc/27.png);
}
.items {
	display: flex;
	justify-content: center;
	padding: 80px 0;
	color: #333333;
	.item {
		width: 210px;
		text-align: center;
		.t1 {
			font-size: 50px;
			margin-bottom: 20px;
		}
		.t2 {
			font-size: 20px;
		}
	}
	.item + .item {
		margin-left: 10%;
	}
}
.common_case{
	height: 440px;
}
</style>
