<template>
	<div class="side_tool">
		<div class="remark">
			<el-icon class="icon" title="在线留言" @click="showMsg = !showMsg"
				><ChatDotSquare
			/></el-icon>
			<div class="msg_content" v-show="showMsg">
				<h3>
					在线留言
					<el-icon @click="showMsg = false"><Close /></el-icon>
				</h3>
				<el-form
					:model="form"
					label-width="80px"
					:rules="rules"
					ref="formRef"
				>
					<el-form-item label="姓名" prop="name">
						<el-input
							v-model="form.name"
							placeholder="请输入姓名"
							:maxlength="10"
						/>
					</el-form-item>
					<el-form-item label="联系方式" prop="phone">
						<el-input
							v-model="form.phone"
							placeholder="请输入11位手机号码"
						/>
					</el-form-item>
					<el-form-item label="公司名称" prop="company">
						<el-input
							v-model="form.company"
							placeholder="请输入公司名称"
						/>
					</el-form-item>
					<el-form-item label="所属行业" prop="industry">
						<el-select
							v-model="form.industry"
							placeholder="请选择所属行业"
							filterable
							allow-create
							default-first-option
							style="width:100%;"
						>
							<el-option
								v-for="item in industrys"
								:label="item"
								:value="item"
								:key="item"
							/>
						</el-select>
					</el-form-item>
					<el-form-item label="联系邮箱" prop="email">
						<el-input
							v-model="form.email"
							placeholder="请输入联系邮箱"
						/>
					</el-form-item>
					<el-form-item label="欢迎留言" prop="remark">
						<el-input
							v-model="form.remark"
							type="textarea"
							placeholder="您的意见和建议对我们很重要，感谢您的留言"
						/>
					</el-form-item>
					<el-form-item>
						<el-button
							type="primary"
							@click="onSubmit(formRef)"
							style="width: 100%"
							>提交</el-button
						>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="border"></div>
		<div class="follow">
			<el-icon class="icon" title="联系我们"><Star /></el-icon>
			<div class="code">
				<img src="../assets/code.png" alt="" />
				<div>联系我们</div>
			</div>
		</div>
		<div class="border"></div>
		<el-icon class="icon" title="回到顶部" @click="toTop"><Top /></el-icon>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'
export default {
	props: {},
	setup() {
		const state = reactive({
			showMsg: false,
			formRef: null,
			form: {
				name: '',
				phone: '',
				company: '',
				industry: null,
				email: '',
				remark: ''
			},
			rules: {
				name: [
					{
						required: true,
						message: '请输入姓名',
						trigger: ['blur']
					}
				],
				phone: [
					{
						required: true,
						message: '请输入联系方式',
						trigger: ['blur']
					},
					{
						pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
						message: '请输入11位手机号码',
						trigger: ['blur']
					}
				],
				company: [
					{
						required: true,
						message: '请输入公司名称',
						trigger: ['blur']
					}
				],
				email: [
					{
						type: 'email',
						message: '请输入正确的邮箱',
						trigger: ['blur', 'change']
					}
				]
			},
			industrys: [
				'农林牧渔业',
				'采矿业',
				'制造业',
				'电热燃水生产与供应',
				'建筑业',
				'批发零售',
				'交通运输仓储',
				'住宿餐饮',
				'互联网',
				'金融业',
				'房地产业',
				'商务服务',
				'科技服务',
				'水利环境与公共设施',
				'居民服务',
				'教育',
				'卫生与社会工作',
				'文体娱乐',
				'公共机构',
				'国际组织'
			]
		})
		const onSubmit = (formEl) => {
			if (!formEl) return
			formEl.validate((valid) => {
				if (valid) {
					axios
						.post(
							'https://www.leanloop.net/apiv4/common/wform/2',
							state.form
						)
						.then((res) => {
							console.log(res.data)
							ElMessage.success('留言成功！感谢您的留言')
							state.showMsg = false
							state.form = {
								name: '',
								phone: '',
								company: '',
								industry: null,
								email: '',
								remark: ''
							}
						})
						.catch((err) => {
							ElMessage.error('提交失败，请稍后重试')
							console.log(err.response.data)
						})
				} else {
					return false
				}
			})
		}
		const toTop = () => {
			let timer = null
			if (document.documentElement.scrollTop > 0) {
				timer = setInterval(() => {
					document.documentElement.scrollTop =
						document.documentElement.scrollTop - 40
					if (document.documentElement.scrollTop == 0) {
						clearInterval(timer)
					}
				}, 1)
			}
		}
		return { ...toRefs(state), toTop, onSubmit }
	}
}
</script>

<style scoped lang="scss">
.side_tool {
	position: fixed;
	right: 30px;
	top: 50%;
	height: 120px;
	width: 40px;
	background: #ffffff;
	box-shadow: 0px 0px 7px 0px rgba(202, 202, 202, 0.5);
	border-radius: 20px;
	z-index: 10;
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	align-items: center;
	.icon {
		color: #666;
		font-size: 16px;
		display: block;
		cursor: pointer;
		&:hover {
			color: #0183f8;
		}
	}
	.border {
		height: 2px;
		width: 20px;
		border-top: 1px solid #ddd;
	}
	// 留言
	.remark {
		position: relative;
		.msg_content {
			h3 {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 20px;
			}
			position: absolute;
			background-color: #fff;
			left: -372px;
			top: -90px;
			// height: 200px;
			width: 342px;
			border: 1px solid #ddd;
			text-align: center;
			padding: 20px;
			box-sizing: border-box;
		}
	}
	// 关注
	.follow {
		position: relative;
		&:hover {
			.code {
				display: block;
			}
		}
		.code {
			display: none;
			position: absolute;
			background-color: #fff;
			left: -210px;
			top: -90px;
			height: 200px;
			width: 180px;
			border: 1px solid #ddd;
			text-align: center;
			padding: 15px 0;
			box-sizing: border-box;
			img {
				width: 150px;
				height: 150px;
			}
			div {
				font-size: 12px;
				color: #666;
			}
		}
	}
	.el-icon {
		cursor: pointer;
	}
}
</style>
